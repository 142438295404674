import { createElement, forwardRef, FC } from "react";
import { useBreakPoints } from "../..";
import { Icon, IconType } from "../Icon";
import { Paragraph } from "../Paragraph";
import { Spacer } from "../Spacer";
import { Stack } from "../Stack";
import { Text } from "../Text";
import styles from "./LinkBox.module.css";

interface LinkBoxProps {
  icon: IconType;
  children: string;
  description?: string;
  onClick?: () => void;
  href?: string;
  target?: HTMLAnchorElement["target"];
  variant?: "default" | "laptop" | "mobile";
}

export const LinkBox = forwardRef<HTMLAnchorElement, LinkBoxProps>(
  (props, ref) => {
    const {
      icon,
      children,
      description,
      onClick,
      href,
      target,
      variant = "default",
    } = props;
    const breakpoint = useBreakPoints();
    const isMobile =
      variant === "default" ? breakpoint.tabletWithDown : variant === "mobile";

    const responsiveVariant = isMobile
      ? styles["variant--mobile"]
      : styles["variant--laptop"];

    const className = [styles.root, responsiveVariant].join(" ");

    return (
      <a
        href={href}
        target={target}
        rel={target === "_blank" ? "noreferrer" : undefined}
        onClick={onClick}
        ref={ref}
        className={className}
      >
        <Stack spacing={isMobile ? "2x" : "4x"} align="center">
          <Icon icon={icon} size={isMobile ? "md" : "xl"} />
          <Stack direction="column">
            <Text weight="bold" size={isMobile ? "base" : "xl"}>
              {children}
            </Text>
            {!isMobile && description && (
              <>
                <Spacer y="2x" />
                <Paragraph color="gray">{description}</Paragraph>
              </>
            )}
          </Stack>
        </Stack>
      </a>
    );
  }
);

LinkBox.displayName = "LinkBox";
