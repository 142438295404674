import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { gql } from "graphql-request";
import {
  LOCAL_STORAGE_APP_BANNER_KEY,
  DURATION_DATES_TO_SHOW_APP_BANNER,
} from "@newt/ui";
import { useAuth } from "@utils/auth";
import { LocalStorageService } from "@utils/webStorage";

const USE_APP_BANNER = gql`
  fragment UseAppBanner_User on User {
    canGetAppDownloadedCoupon
  }
`;

export const useAppBanner = () => {
  const { user, isLoading: isUserLoading } = useAuth();
  const notLoggedIn = !isUserLoading && !user?.isActive;

  // 前回閉じたのは1ヶ月以上前かの判定
  const [closedIsoDate, setClosedIsoDate] = useState<string | null>();
  useEffect(() => {
    LocalStorageService.getItem<string>(LOCAL_STORAGE_APP_BANNER_KEY).then(
      (closedIsoDate) => setClosedIsoDate(closedIsoDate)
    );
  }, []);
  let isDaysExceeded = false; // 1ヶ月以上経過
  if (typeof closedIsoDate === "string") {
    const closeBannerDateTime = DateTime.fromISO(closedIsoDate);
    const currentTime = DateTime.now();
    isDaysExceeded =
      currentTime.diff(closeBannerDateTime, "days").get("days") >
      DURATION_DATES_TO_SHOW_APP_BANNER;
  }

  // 【閉じたことがない人 または 1ヶ月以上前に閉じた人】 で、【未ログインユーザー または 予約履歴なし】
  const showAppBanner =
    (closedIsoDate === null || isDaysExceeded) &&
    (notLoggedIn || !!user?.canGetAppDownloadedCoupon);

  // 閉じたときにISO日付を格納する
  const handleAppBannerClose = () => {
    const closeBannerDateInIso = DateTime.now().toISO() || "";
    LocalStorageService.setItem(
      LOCAL_STORAGE_APP_BANNER_KEY,
      closeBannerDateInIso
    );
    setClosedIsoDate(closeBannerDateInIso);
  };

  return {
    showAppBanner,
    handleAppBannerClose,
  };
};
