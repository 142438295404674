import { useQuery } from "@tanstack/react-query";
import { gql } from "graphql-request";
import { graphql } from "@utils/graphql";
import { useAuth, useAnonymousUser } from "@utils/auth";

const USE_FETCH_SEARCH_HISTORY_QUERY = gql`
  query useFetchSearchHistory($first: Int, $last: Int) {
    me {
      tourSearchHistory(first: $first, last: $last) {
        edges {
          node {
            ...HistoryContent_UserTourSearchHistory
          }
        }
      }
    }
  }
`;

interface UseFetchSearchHistoryArgs {
  enabled?: boolean;
}

export const useFetchSearchHistory = ({
  enabled = false,
}: UseFetchSearchHistoryArgs) => {
  const auth = useAuth();
  const anonymousUser = useAnonymousUser();
  const { data, isLoading } = useQuery({
    queryKey: ["useFetchSearchHistory"],
    queryFn: () => graphql.useFetchSearchHistory({ first: 6 }),
    enabled: !!auth.user && enabled,
    refetchOnMount: true,
  });
  const tourSearchHistory =
    data?.me?.tourSearchHistory ||
    anonymousUser.data?.anonymousUser.me?.tourSearchHistory;

  return {
    histories: tourSearchHistory?.edges.map((edge) => edge.node),
    isLoading: isLoading || anonymousUser.isLoading,
  };
};
