import { CreateForm, FieldErrors } from "@utils/form";
import { ConfirmationResult } from "firebase/auth";
import * as yup from "yup";

export interface PhoneVerificationFormValues {
  verificationCode: string;
}

export interface FormOptions {
  confirmationResult: ConfirmationResult;
  setIsLoggingIn?: (value: boolean) => void;
}

export const usePhoneVerificationForm = ({
  confirmationResult,
  setIsLoggingIn,
}: FormOptions) => {
  const onSubmit = (data: PhoneVerificationFormValues) => {
    return confirmationResult
      ?.confirm(data.verificationCode)
      .then(() => setIsLoggingIn?.(true))
      .catch((err) => {
        throw new FieldErrors({
          verificationCode: "認証コードを正しく入力してください。",
        });
      });
  };

  return CreateForm<PhoneVerificationFormValues>({
    rules: {
      verificationCode: yup.string().length(6).required().label("認証コード"),
    },
    onSubmit,
    validateMode: "onTouched",
  });
};
