import React, { FC, useEffect, useState } from "react";
import { useAuth } from "@utils/auth";
import { Button, Modal, useBreakPoints } from "@newt/ui";
import { ReservationShare } from "./ReservationShare";
import { useLocalStorage } from "@utils/webStorage/useLocalStorage";
import { LocalStorageService } from "@utils/webStorage";
import { UserStatus } from "@graphql/types";
import styles from "./ReservationShareModal.module.css";

const LOCAL_STORAGE_KEY = "reservationShareModal.bookingId";
export const LOCAL_STORAGE_KEY_FOR_RESERVATION_SHARE_MODAL_BOOKING_ID =
  LOCAL_STORAGE_KEY;

interface ReservationShareModalProps {
  onUpdateStoreModalIds: () => void;
}

export const ReservationShareModal: FC<ReservationShareModalProps> = (
  props
) => {
  const { onUpdateStoreModalIds } = props;
  const auth = useAuth();
  const bookingId = useLocalStorage<string>(LOCAL_STORAGE_KEY);

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    LocalStorageService.removeItem(LOCAL_STORAGE_KEY);
    onUpdateStoreModalIds();
  };

  useEffect(() => {
    if (auth.user?.status === UserStatus.ACTIVE && !!bookingId) {
      setIsOpen(true);
    }
  }, [auth.user, bookingId]);

  return (
    <Modal
      title="Your Next Travel is...🛫"
      isActive={isOpen}
      fullScreen={false}
      onClose={handleClose}
      action={
        <Button onClick={handleClose} variant="outline" color="default">
          とじる
        </Button>
      }
    >
      <div className={styles.resizeIfSmallDevice}>
        <ReservationShare
          reservationId={bookingId ? parseInt(bookingId, 10) : undefined}
          isWebview={false}
        />
      </div>
    </Modal>
  );
};
