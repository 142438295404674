import { FC, useState } from "react";
import styles from "./SearchButton.module.css";
import { Icon } from "@newt/ui";
import { useSearchForm } from "../SearchBox/hooks/useSearchForm";

type SearchButtonProps = {
  readonly onClick: () => void;
};

export const SearchButton: FC<SearchButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.searchButton} type="button" onClick={onClick}>
      <div className={styles.searchButtonLabel}>ツアーをさがす</div>
      <div className={styles.searchIcon}>
        <Icon icon="search" color="white" size="sm" />
      </div>
    </button>
  );
};
