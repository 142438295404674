import {
  AppDownload,
  FollowUs,
  Footer,
  Header,
  OpenInApp,
} from "@components/common";
import { useIsWebview } from "@utils/webview";
import { FC, ReactNode } from "react";
import { WebviewLayout } from "../WebviewLayout";
import styles from "./DefaultLayout.module.css";
import { destinationsQuery } from "@graphql/types";
import { UtmCampaign } from "@utils/dynamicLink";
import {
  DOWNLOAD_APP_BANNER_LINK_SP,
  FixedAppBannerForPc,
  FixedAppBannerForSp,
} from "@newt/ui";
import { useAppBanner } from "./useAppBanner";
import { useAppLink } from "@utils/useAppLink";
import { GSIScript } from "@utils/auth";

export interface DefaultLayoutProps {
  hasNavigation?: boolean;
  children?: ReactNode;
  headerNoFollow?: boolean;
  headerShow?: boolean;
  mobileHeaderShow?: boolean;
  isWebview?: boolean;
  openInAppShow?: boolean;
  classNames?: { root?: string; header?: string };
  openInAppCustomPath?: string;
  destinations?: destinationsQuery["destinations"];
  utmCampaign?: UtmCampaign;
  catchphrase?: string;
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({
  children,
  hasNavigation,
  headerNoFollow = false,
  headerShow = true,
  mobileHeaderShow = true,
  isWebview: isWebviewFromProps,
  openInAppShow = true,
  classNames,
  openInAppCustomPath,
  destinations,
  utmCampaign,
  catchphrase,
}) => {
  const { showAppBanner, handleAppBannerClose } = useAppBanner();
  const isWebviewFromHooks = useIsWebview();
  const isWebviewValue = isWebviewFromProps || isWebviewFromHooks;
  const isWebview = isWebviewValue === undefined || isWebviewValue === true;

  /** fixed banner link */
  const fdl =
    useAppLink({
      path: openInAppCustomPath,
      utmCampaign,
      utmContent: "fixed_banner",
    }) || "";
  // adjust  → firebase dynamic link with redirect key → open app if installed, otherwise redirect to app store
  const fixedBannerLink =
    DOWNLOAD_APP_BANNER_LINK_SP + "&redirect=" + encodeURIComponent(fdl);

  if (isWebview) {
    return <WebviewLayout>{children}</WebviewLayout>;
  }

  const rootClasses = [styles.root, classNames?.root || ""].join(" ");

  return (
    <>
      <div className={rootClasses}>
        {!isWebview && (
          <>
            {openInAppShow && (
              <OpenInApp
                utmCampaign={utmCampaign}
                customPath={openInAppCustomPath}
              />
            )}
            <div className="tabletWithDown">
              {showAppBanner && (
                <FixedAppBannerForSp
                  downloadLink={fixedBannerLink || ""}
                  onClose={handleAppBannerClose}
                />
              )}
            </div>
            {headerShow && (
              <div
                className={[
                  !mobileHeaderShow ? styles.hide : "",
                  classNames?.header,
                ].join(" ")}
              >
                <div className={styles.pc}>
                  <Header
                    hasNavigation={true}
                    noFollow={headerNoFollow}
                    catchphrase={catchphrase}
                  />
                </div>
                <div className={styles.sp}>
                  <Header
                    hasNavigation={hasNavigation}
                    noFollow={headerNoFollow}
                  />
                </div>
              </div>
            )}
          </>
        )}
        <div className={styles.inner}>{children}</div>
        <AppDownload utmCampaign={utmCampaign} />
        <FollowUs />
        <Footer destinations={destinations} />
        {showAppBanner && (
          <div className={styles.fixedAppBannerForPc}>
            <FixedAppBannerForPc onClose={handleAppBannerClose} />
          </div>
        )}
      </div>
      {!isWebview && <GSIScript />}
    </>
  );
};
