import { Header, OpenInApp } from "@components/common";
import { BasicFooter, Container, useToast } from "@newt/ui";
import { useIsWebview } from "@utils/webview";
import { FC, ReactNode } from "react";
import { WebviewLayout } from "../WebviewLayout";
import styles from "./FriendCodeLayout.module.css";
import { LoadingLayout } from "../LoadingLayout";
import { useAuthenticateIfNotWebview } from "./useAuthenticateIfNotWebview";
import { useAuth } from "@utils/auth";

interface FriendCodeLayoutProps {
  children: ReactNode;
  isWebview?: boolean;
}

export const FriendCodeLayout: FC<FriendCodeLayoutProps> = ({
  children,
  isWebview: isWebviewFromProps,
}) => {
  useAuthenticateIfNotWebview();
  const { isLoading, user } = useAuth();
  const isWebviewFromHooks = useIsWebview();
  const isWebviewValue = isWebviewFromProps || isWebviewFromHooks;
  const isWebview = isWebviewValue === undefined || isWebviewValue === true;

  if (isWebview) {
    return <WebviewLayout>{children}</WebviewLayout>;
  }

  if (isLoading || (!isLoading && !user)) {
    return <LoadingLayout />;
  }

  return (
    <div className={styles.root}>
      <OpenInApp />
      <Header />
      <div className={styles.body}>
        <Container spacing={{ mobile: "0", tablet: "8x" }}>
          {children}
        </Container>
      </div>
      <BasicFooter />
    </div>
  );
};
