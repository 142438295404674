import { toTourPageQuery } from "@components/pages/tours/[tourId]/query";
import { useToast } from "@newt/ui";
import { ROUTES, withQuery } from "@routes";
import { gql } from "graphql-request";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./auth";
import { graphql } from "./graphql";
import {
  FormatForLogEventVariables,
  formatForAddToWishlistEvent,
  MarketingService,
} from "@utils/marketing";
import { RouterQueryParams } from "./router/routerQuery";

export const USE_WISHLIST_TOGGLE_ADD_TOUR_TO_WISHLIST_MUTATION = gql`
  mutation UseWishlistToggle_AddTourToWishlist($tourId: Int!) {
    AddTourToWishlist(tourId: $tourId) {
      success
    }
  }
`;

export const USE_WISHLIST_TOGGLE_DELETE_TOUR_TO_WISHLIST_MUTATION = gql`
  mutation UseWishlistToggle_DeleteTourFromWishlist($tourId: Int!) {
    DeleteTourFromWishlist(tourId: $tourId) {
      success
    }
  }
`;

export type WishlistToggleHandler = (after: boolean) => void;

export const useTourWishlistToggle = (args: {
  tourId: number;
  tourUrl: string;
  defaultValue: boolean;
  logEventOptions: FormatForLogEventVariables;
  onWishlistToggle?: WishlistToggleHandler;
  toPageQuery?: RouterQueryParams;
}) => {
  const {
    tourId,
    tourUrl,
    defaultValue,
    logEventOptions,
    onWishlistToggle,
    toPageQuery,
  } = args;
  const [inWishlist, setInWishlist] = useState(defaultValue);

  useEffect(() => {
    setInWishlist(defaultValue);
  }, [defaultValue]);

  const { user, setOnLoginRedirect } = useAuth();
  const router = useRouter();
  const queryClient = useQueryClient();
  const toast = useToast();

  const toggleInWishlist = useCallback(() => {
    if (!user) {
      setOnLoginRedirect(() => async () => {
        queryClient.clear();
        await graphql
          .UseWishlistToggle_AddTourToWishlist({ tourId })
          .then(() => {
            const variables = formatForAddToWishlistEvent(logEventOptions);
            MarketingService.logEvent.addToWishlist(variables);
            MarketingService.logEvent.brazeRegisterTourWishList({
              tourId,
            });
          })
          .catch(() => toast.error("お気にいりの追加に失敗しました"));
        router.push(
          withQuery(
            tourUrl,
            toTourPageQuery({ ...router.query, ...toPageQuery })
          ),
          undefined,
          {
            shallow: true,
          }
        );
      });
      router.push(ROUTES.signin.index());
      return;
    }

    if (inWishlist) {
      setInWishlist(false);
      graphql
        .UseWishlistToggle_DeleteTourFromWishlist({ tourId })
        .then(() => {
          onWishlistToggle?.(false);
        })
        .catch(() => {
          toast.error("お気にいりの削除に失敗しました");
          setInWishlist(true);
        });
    } else {
      setInWishlist(true);
      graphql
        .UseWishlistToggle_AddTourToWishlist({ tourId })
        .then(() => {
          const variables = formatForAddToWishlistEvent(logEventOptions);
          MarketingService.logEvent.addToWishlist(variables);
          MarketingService.logEvent.brazeRegisterTourWishList({
            tourId,
          });
          onWishlistToggle?.(true);
        })
        .catch(() => {
          toast.error("お気にいりの追加に失敗しました");
          setInWishlist(false);
        });
    }
  }, [
    router,
    user,
    inWishlist,
    setOnLoginRedirect,
    queryClient,
    tourId,
    toast,
    setInWishlist,
    onWishlistToggle,
  ]);

  return {
    inWishlist,
    toggleInWishlist,
  };
};
