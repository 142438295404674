import { ReactNode, FC } from "react";
import { Tag } from "../Tag";
import { Text } from "../Text";
import styles from "./FieldGroup.module.css";

interface FieldError {
  type: unknown;
  message?: string;
}

export interface FieldGroupProps {
  label?: string;
  required?: boolean;
  help?: ReactNode;
  error?: string | FieldError;
  children: ReactNode;
}

export const FieldGroup: FC<FieldGroupProps> = ({
  label,
  required,
  help,
  error,
  children,
}) => {
  const errorMessage = typeof error === "object" ? error.message : error;
  return (
    <div className={`${styles.root} ${error ? "error-field" : ""}`}>
      {label && (
        <div className={styles.label}>
          <Text weight="bold">{label}</Text>
          {required && <Tag color="danger">必須</Tag>}
        </div>
      )}
      <div className={styles.body}>{children}</div>
      {help && !error && (
        <Text color="default" size="sm">
          {help}
        </Text>
      )}
      {error && (
        <Text color="danger" size="sm">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};
