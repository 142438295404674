import { useMutation } from "@tanstack/react-query";
import { graphql } from "@utils/graphql";
import { LocalStorageService } from "@utils/webStorage";
import { gql } from "graphql-request";

const LOCAL_STORAGE_DEVICE_PSEUDO_ID_KEY = "devicePseudoId";

const USE_MARK_LOGIN_ACTIVITY = gql`
  mutation UseMarkLoginActivity_Mutation($devicePseudoId: String) {
    MarkLoginActivity(input: { devicePseudoId: $devicePseudoId }) {
      devicePseudoId
    }
  }
`;

/**
 * 不正対策のため、会員登録・ログインのタイミングでlocal storageにあるdevicePseudoIdを送信します。
 * 特定のデバイスに紐づけるために、ログアウトや会員削除を跨いでもできる限りdevicePseudoIdを保持します。
 */
export const useMarkLoginActivity = () => {
  const { mutateAsync } = useMutation({
    mutationFn: async () => {
      const devicePseudoId = await LocalStorageService.getItem<string>(
        LOCAL_STORAGE_DEVICE_PSEUDO_ID_KEY
      );

      const data = await graphql.UseMarkLoginActivity_Mutation({
        devicePseudoId,
      });

      if (data.MarkLoginActivity?.devicePseudoId) {
        LocalStorageService.setItem(
          LOCAL_STORAGE_DEVICE_PSEUDO_ID_KEY,
          data.MarkLoginActivity.devicePseudoId
        );
      }
    },
  });

  return { markLoginActivityMutation: mutateAsync };
};
