import { FC, HTMLProps } from "react";
import styles from "./Logo.module.css";
import { LogoHorizontal } from "./logos/LogoHorizontal";
import { LogoVertical } from "./logos/LogoVertical";
import { LogoIcon } from "./logos/LogoIcon";

type LogoVariant = "vertical" | "horizontal" | "icon";
export type LogoColor = "primary" | "white";

interface LogoProps
  extends Omit<HTMLProps<HTMLDivElement>, "width" | "height"> {
  variant?: LogoVariant;
  color?: LogoColor;
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
}

const logos = {
  vertical: LogoVertical,
  horizontal: LogoHorizontal,
  icon: LogoIcon,
};

export const Logo: FC<LogoProps> = ({
  color = "primary",
  variant = "vertical",
  width = undefined,
  height = undefined,
}) => {
  const LogoSvg = logos[variant];
  return (
    <div
      className={`${styles.root} ${styles[`variant--${variant}`]} ${
        styles[`color--${color}`]
      }`}
      style={{ width, height }}
    >
      <LogoSvg />
    </div>
  );
};
