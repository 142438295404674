import { FC } from "react";
import { gql } from "graphql-request";
import {
  ReservationCheckoutIndexPage_BookingFragment,
  ReservationPriceCard_BookingPriceFragment,
  ReservationPriceCard_BookingPriceSummaryFragment,
} from "@graphql/types";
import { Card, Spacer, Stack } from "@newt/ui";
import { findPrices } from "./reservationPriceCardUtils";
import { PointsDisplay } from "./ReservationPriceCardPointsDisplay";
import styles from "./ReservationPriceCard.module.css";
import { ReservationPriceCardModal } from "./ReservationPriceCardModal";

const RESERVATION_PRICE_CARD = gql`
  fragment ReservationPriceCard_BookingPriceSummary on BookingPriceSummary {
    label
    type
    totalPrice
    description
  }

  fragment ReservationPriceCard_BookingPrice on BookingPrice {
    summary {
      ...ReservationPriceCard_BookingPriceSummary
      ...ReservationPriceCardModalSummary_BookingPriceSummary
    }
    totalPrice
    scheduledPoints
    sections {
      ...ReservationPriceCardModalBlock_BookingPriceSection
    }
  }
`;

interface Props {
  price: ReservationPriceCard_BookingPriceFragment;
  remarksForLocalTax?: ReservationCheckoutIndexPage_BookingFragment["remarksForLocalTax"];
}

export const ReservationPriceCard: FC<Props> = (props) => {
  const { price, remarksForLocalTax } = props;

  const priceItems =
    findPrices<ReservationPriceCard_BookingPriceSummaryFragment>(price.summary);

  return (
    <Card>
      {/** 旅行代金 */}
      {priceItems.basePrice && (
        <PriceCardItem
          label={priceItems.basePrice.label}
          price={priceItems.basePrice.totalPrice}
          variant="gray"
        />
      )}
      {/** 諸税 */}
      {priceItems.taxPrice && (
        <PriceCardItem
          label={priceItems.taxPrice.label}
          price={priceItems.taxPrice.totalPrice}
          variant="gray"
        />
      )}
      {/** その他追加料金 */}
      {priceItems.extraPrice && (
        <PriceCardItem
          label={priceItems.extraPrice.label}
          price={priceItems.extraPrice.totalPrice}
          variant="gray"
        />
      )}

      {/** クーポン */}
      {priceItems.coupon && (
        <PriceCardItem
          label={"クーポン利用"}
          price={priceItems.coupon.totalPrice || ""}
          variant="red"
        />
      )}

      {/** ポイント */}
      {priceItems.pointsForPayment && (
        <PriceCardItem
          label={"ポイント利用"}
          price={priceItems.pointsForPayment.totalPrice || ""}
          variant="red"
        />
      )}

      {/** その他 */}

      {/** お支払い金額 */}
      <PriceCardItem
        label="お支払い金額"
        price={price.totalPrice}
        variant="bold"
      />

      {/** ポイント */}
      <Stack as="div" justify={"end"}>
        <PointsDisplay points={price.scheduledPoints} hasInfo={true} />
      </Stack>

      <Spacer y="4x" />

      <ReservationPriceCardModal
        price={price}
        remarksForLocalTax={remarksForLocalTax}
      />
    </Card>
  );
};

interface PriceCardItemProps {
  label: string;
  price: string;
  variant: "gray" | "red" | "bold";
}

const PriceCardItem: FC<PriceCardItemProps> = ({ label, price, variant }) => {
  const className = [
    styles.priceCardItem,
    styles[`priceCardItem--${variant}`],
  ].join(" ");

  return (
    <div className={className}>
      <span>{label}</span>
      <span>{price}</span>
    </div>
  );
};
