import { FC, MouseEventHandler } from "react";
import { LINE_CONCIERGE_LINK } from "../../constants";
import styles from "./LineConcierge.module.css";
import lineIcon from "./assets/icon_line.png";

interface Props {
  showSupportText?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const LineConcierge: FC<Props> = ({
  onClick,
  showSupportText = true,
}) => {
  return (
    <section className={styles.root}>
      <p className={styles.message1}>トラベルコンシェルジュに相談する🌼</p>
      {showSupportText && (
        <p className={styles.message2}>
          ご希望の条件で見つからない場合や、プランにないご要望がある場合は、お気軽にご相談ください🙌
        </p>
      )}
      <ul className={styles.list}>
        <li>
          <span>どんなプランがいいかわからない</span>
        </li>
        <li>
          <span>さまざまなエリアを周遊したい</span>
        </li>
        <li>
          <span>ハネムーンプランがあるか確認したい&nbsp;など</span>
        </li>
      </ul>
      <a className={styles.link} href={LINE_CONCIERGE_LINK} onClick={onClick}>
        <button>
          <img
            src={lineIcon.src}
            alt="LINEで旅行の相談をする"
            width={24}
            height={24}
            className={styles.lineIcon}
          />
          LINEで旅行の相談をする
        </button>
      </a>
    </section>
  );
};
