import { useRouter } from "next/router";
import { FC, useEffect, useState, createContext, ReactNode } from "react";

export interface ContextProps {
  isWebview: boolean | undefined;
}

interface WebviewProviderProps {
  children: ReactNode;
}

export const WebviewContext = createContext<ContextProps>({
  isWebview: true,
});

export const WebviewProvider: FC<WebviewProviderProps> = ({ children }) => {
  const [isWebview, setIsWebview] = useState<boolean | undefined>(undefined);
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (router.query["screen"] === "webview") {
      localStorage && localStorage.setItem("screen", "webview");
      setIsWebview(true);
      return;
    }

    if (router.query["screen"] === "desktop") {
      localStorage && localStorage.removeItem("screen");
      setIsWebview(false);
      return;
    }

    if (localStorage && localStorage.getItem("screen") === "webview") {
      setIsWebview(true);
      return;
    }

    if (!isWebview) {
      setIsWebview(false);
    }
  }, [router.query, isWebview, router.isReady]);

  const value = {
    isWebview,
  };

  return (
    <WebviewContext.Provider value={value}>{children}</WebviewContext.Provider>
  );
};
