import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@utils/auth";
import { graphql } from "@utils/graphql";
import { gql } from "graphql-request";

const USE_FETCH_MY_PAGE_REFERRAL_CAMPAIGN_QUERY = gql`
  query ReferralCampaign_Query {
    referralCampaign {
      campaign {
        pointAmount
      }
    }
  }
`;

export const useFetchMyPageReferralCampaign = () => {
  const auth = useAuth();
  const { data, isPending } = useQuery({
    queryKey: ["myPageReferralCampaign"],
    queryFn: () => graphql.ReferralCampaign_Query(),
    enabled: !!auth.user,
  });

  return {
    myPageReferralCampaign: data?.referralCampaign.campaign,
    isLoading: isPending,
  };
};
