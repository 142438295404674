import styles from "./Footer.module.css";
import { Container } from "../../Container";
import { Stack } from "../../Stack";
import { Text } from "../../Text";
import { LineIcon } from "./Icons/LineIcon";
import { TwitterIcon } from "./Icons/TwitterIcon";
import { InstagramIcon } from "./Icons/InstagramIcon";
import { TikTokIcon } from "./Icons/TikTokIcon";
import { YouTubeIcon } from "./Icons/YouTube";
import { Accordion } from "../../Accordion";
import { ReactNode, FC } from "react";
import {
  INSTAGRAM_LINK,
  LINE_LINK,
  TIKTOK_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
} from "../../../constants";
import { useBreakPoints } from "../../../hooks/useBreakPoints";

const SocialNetworkLinks = () => {
  return (
    <Stack direction="row" spacing={{ mobile: "2x" }}>
      <a
        href={LINE_LINK}
        className={styles.snsLink}
        target="_blank"
        rel="noreferrer"
      >
        <LineIcon />
      </a>
      <a
        href={TWITTER_LINK}
        className={styles.snsLink}
        target="_blank"
        rel="noreferrer"
      >
        <TwitterIcon />
      </a>
      <a
        href={INSTAGRAM_LINK}
        className={styles.snsLink}
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon />
      </a>
      <a
        href={TIKTOK_LINK}
        className={styles.snsLink}
        target="_blank"
        rel="noreferrer"
      >
        <TikTokIcon />
      </a>
      <a
        href={YOUTUBE_LINK}
        className={styles.snsLink}
        target="_blank"
        rel="noreferrer"
      >
        <YouTubeIcon />
      </a>
    </Stack>
  );
};

interface FooterProps {
  customNavGroupsArr?: NavGroup[][];
}

type NavItem = {
  label: string;
  href: string;
};

export type NavSubGroup = {
  id: string;
  label: string;
  items: NavItem[];
};

export type NavGroup = {
  id: string;
  label: string;
  items?: NavItem[];
  subGroups?: NavSubGroup[];
  allowToggle: boolean;
  children?: ReactNode;
};

export const Footer: FC<FooterProps> = ({ customNavGroupsArr = [] }) => {
  const navGroups: NavGroup[][] = [
    ...customNavGroupsArr,
    [
      {
        id: "mag",
        label: "最新の海外旅行ガイド",
        items: [
          { label: "旅行ガイド一覧", href: "/mag" },
          { label: "ハワイの記事", href: "/hwi/mag" },
          { label: "グアムの記事", href: "/gum/mag" },
          { label: "タイの記事", href: "/tha/mag" },
          { label: "台湾の記事", href: "/twn/mag" },
          { label: "韓国の記事", href: "/kor/mag" },
          { label: "シンガポールの記事", href: "/sgp/mag" },
          { label: "フランスの記事", href: "/fra/mag" },
          { label: "イタリアの記事", href: "/ita/mag" },
          { label: "スペインの記事", href: "/esp/mag" },
          { label: "オーストラリアの記事", href: "/aus/mag" },
        ],
        allowToggle: true,
      },
    ],
    [
      {
        id: "theme",
        label: "テーマ別旅行特集",
        items: [{ label: "すべての特集をみる", href: "/spl-all" }],
        allowToggle: true,
      },
    ],
    [
      {
        id: "about",
        label: "NEWTについて",
        items: [
          { label: "運営会社について", href: "/company" },
          { label: "はじめての方へ", href: "/app" },
          { label: "NEWT Brand", href: "/brand" },
        ],
        allowToggle: false,
      },
      {
        id: "services",
        label: "NEWTのサービス",
        items: [
          { label: "NEWTポイント", href: "/point" },
          { label: "LINEで旅行の相談", href: "/travel-concierge" },
          { label: "VIP旅行の相談", href: "/vip" },
          {
            label: "広告掲載について",
            href: "https://www.reiwatravel.co.jp/contact",
          },
        ],
        allowToggle: false,
      },
      {
        id: "support",
        label: "サポートについて",
        items: [
          {
            label: "ヘルプセンター",
            href: "/help",
          },
        ],
        allowToggle: false,
        children: <SocialNetworkLinks />,
      },
    ],
  ];

  const breakPoints = useBreakPoints();

  return (
    <footer className={styles.root}>
      <Container spacing="5x">
        <nav className={styles.nav}>
          <div className={styles.navGroups}>
            {navGroups.map((navGroupColumn, index) => (
              <ul key={index} className={styles.navGroupColumn}>
                {navGroupColumn.map((navGroup) => (
                  <li key={navGroup.id}>
                    <Stack spacing="4x" direction="column">
                      {!navGroup.allowToggle ? (
                        <>
                          <Text weight="bold">{navGroup.label}</Text>
                          <ul
                            className={`${styles.navGroupItems} ${styles.directionColumn}`}
                          >
                            {navGroup.items?.map((item) => (
                              <li key={item.href}>
                                <a
                                  href={item.href}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={styles.navLink}
                                >
                                  <Text size="sm">{item.label}</Text>
                                </a>
                              </li>
                            ))}
                          </ul>
                          {navGroup.children}
                        </>
                      ) : (
                        <Accordion
                          summary={<Text weight="bold">{navGroup.label}</Text>}
                          content={
                            <>
                              {navGroup.items && (
                                <ul
                                  className={`${styles.navGroupItems} ${styles.directionRow}`}
                                >
                                  {navGroup.items?.map((item) => (
                                    <li key={item.href}>
                                      <a
                                        href={item.href}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={styles.navLink}
                                      >
                                        <Text size="sm">{item.label}</Text>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                              {navGroup.subGroups && (
                                <ul
                                  className={`${styles.navSubGroupItems} ${styles.directionRow}`}
                                >
                                  {navGroup.subGroups?.map((subGroup) => (
                                    <li
                                      key={subGroup.id}
                                      className={styles.navSubGroup}
                                    >
                                      <span className={styles.navSubGroupLabel}>
                                        {subGroup.label}
                                      </span>
                                      <ul
                                        className={`${styles.navGroupItems} ${styles.directionRow}`}
                                      >
                                        {subGroup.items.map((item) => (
                                          <li key={item.href}>
                                            <a
                                              href={item.href}
                                              target="_blank"
                                              rel="noreferrer"
                                              className={styles.navLink}
                                            >
                                              <Text size="sm">
                                                {item.label}
                                              </Text>
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </>
                          }
                          isExpanded={!breakPoints.mobileOnly}
                          allowToggle={breakPoints.mobileOnly}
                        />
                      )}
                    </Stack>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </nav>
        <div className={styles.bar}>
          <Stack
            direction={{ mobile: "column", tablet: "row" }}
            spacing={{ mobile: "3x", tablet: "6x" }}
          >
            <a
              href="https://www.reiwatravel.co.jp/"
              className={styles.navLink}
              target="_blank"
              rel="noreferrer"
            >
              <Text size="sm">運営会社</Text>
            </a>
            <a
              href="/terms"
              className={styles.navLink}
              target="_blank"
              rel="noreferrer"
            >
              <Text size="sm">利用規約</Text>
            </a>
            <a
              href="/privacy"
              className={styles.navLink}
              target="_blank"
              rel="noreferrer"
            >
              <Text size="sm">プライバシーポリシー</Text>
            </a>
            <a
              href="/license"
              className={styles.navLink}
              target="_blank"
              rel="noreferrer"
            >
              <Text size="sm">旅行業登録票</Text>
            </a>
          </Stack>
          <p className={styles.copyright}>
            <Text size="sm">© 2021 Reiwa Travel, Inc.</Text>
          </p>
        </div>
      </Container>
    </footer>
  );
};
