import { Path, UseFormSetError } from "react-hook-form";
import { GraphQLErrorFormat } from "./FormError";

export const setFieldErrors = <T extends {}>(
  { response }: GraphQLErrorFormat,
  setError: UseFormSetError<T>
) => {
  if (!response || !response.errors) {
    return;
  }

  const errors = response.errors[0];
  const fields = errors.fields;

  if (errors.code === "VALIDATION_FAILED" && !fields) {
    return errors.message;
  }

  if (errors.code === "PAYMENT_ERROR" && !fields) {
    return errors.message;
  }

  if (fields) {
    Object.keys(fields).forEach((key) => {
      setError(key as Path<T>, { type: "api", message: fields[key] });
    });

    // scroll until first element with error ??
    // document.getElementsByClassName("error-field")[0].scrollIntoView();
  }

  return false;
};
