import { useRouter } from "next/router";
import { gql } from "graphql-request";
import { ANONYMOUS_USER_TOKEN } from "@constants";
import { alert, useToast } from "@newt/ui";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "@utils/auth";
import { graphql } from "@utils/graphql";
import { LocalStorageService } from "@utils/webStorage";
import { MarketingService } from "@utils/marketing";
import { firebase } from "@utils/firebase";
import { mapProviderDataToAuthMethod } from "@utils/mapProviderDataToAuthMethod";
import { GraphQLErrorFormat } from "@utils/form";
import { toRegisterPageQuery } from "./query";

const USE_CREATE_SIMPLE_USER = gql`
  mutation UseCreateSimpleUser($input: CreateSimpleUserInput!) {
    CreateSimpleUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const useCreateSimpleUser = () => {
  const auth = useAuth();
  const toast = useToast();
  const router = useRouter();
  const query = toRegisterPageQuery(router.query);
  const authMethod = firebase.auth.currentUser
    ? mapProviderDataToAuthMethod(firebase.auth.currentUser?.providerData)
    : "";

  const { isPending, mutateAsync: createSimpleUserMutation } = useMutation({
    mutationFn: async (input?: { email: string; newsletter: boolean }) => {
      const anonymousUserToken =
        await LocalStorageService.getItem<string>(ANONYMOUS_USER_TOKEN);

      try {
        const data = await graphql.UseCreateSimpleUser({
          input: {
            ...input,
            anonymousToken: anonymousUserToken,
          },
        });
        auth.refetch();
        LocalStorageService.removeItem(ANONYMOUS_USER_TOKEN);
        const userId = data.CreateSimpleUser.user.id.toString();
        MarketingService.logEvent.signUp({
          uid: userId,
          user_id: userId,
          user_status: "member",
          previous_module_type: query.previousModuleType,
          auth_method: authMethod,
        });
        MarketingService.logEvent.brazeChangeUser({
          userId: data.CreateSimpleUser.user.id,
        });
        setTimeout(() => {
          // signUp event after changeUser when recreate user
          MarketingService.logEvent.brazeSignUp();
        }, 1000);

        return data;
      } catch (error) {
        const typedError = error as GraphQLErrorFormat;
        const message = typedError?.response?.errors?.[0]?.message;
        alert(message || "エラーが発生しました。");
        throw error;
      }
    },
  });

  return {
    createSimpleUserMutation,
    isLoading: isPending,
  };
};
