import { COOKIE_AUTHORIZATION, COOKIE_TOKEN } from "@constants";
import { UserContextFragment, UserStatus } from "@graphql/types";
import { ROUTES } from "@routes";
import { useQuery } from "@tanstack/react-query";
import { cookies } from "@utils/cookies";
import { useFirebaseAuth } from "@utils/firebase";
import { graphql } from "@utils/graphql";
import { MarketingService } from "@utils/marketing";
import * as Sentry from "@sentry/nextjs";
import { gql } from "graphql-request";
import { useRouter } from "next/router";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { useAuthorizationToken, useMarkLoginActivity } from "../hooks";

export const USER_CONTEXT_USER_FRAGMENT = gql`
  fragment UserContext on User {
    id
    status
    lastName
    firstName
    displayName
    isActive
    email
    phone
    gender
    passportNumber
    passportExpirationDate
    birthdate
    countryId
    customerId
    newsletter
    airportId
    points {
      ...CoreUserPoint_UserPoints
      ...PointPage_UserPoints
    }
    ...UseAppBanner_User
    ...ConsentModal_User
  }
`;

export const AUTH_CONTEXT_QUERY = gql`
  query AuthContext_Query {
    me {
      ...UserContext
    }
  }

  ${USER_CONTEXT_USER_FRAGMENT}
`;

export type User = UserContextFragment | null;

type OnLoginRedirect = () => Promise<void>;

export type AuthContextState = {
  user?: User;
  userStatus?: UserStatus;
  refetch: () => void;
  signOut: () => void;
  onLoginRedirect: OnLoginRedirect;
  setOnLoginRedirect: Dispatch<SetStateAction<OnLoginRedirect | undefined>>;
  isLoading: boolean;
  firebase?: ReturnType<typeof useFirebaseAuth>;
  routerPushIfAuthenticated: (path: string) => Promise<boolean>;
};

export const AuthContext = createContext<AuthContextState>({
  signOut: () => null,
  refetch: () => null,
  onLoginRedirect: () => Promise.resolve(),
  setOnLoginRedirect: () => Promise.resolve(),
  isLoading: false,
  routerPushIfAuthenticated: () => Promise.resolve(true),
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children, ...props }) => {
  const token = useAuthorizationToken();
  const router = useRouter();
  const firebase = useFirebaseAuth(token);
  const { markLoginActivityMutation } = useMarkLoginActivity();

  const [onLoginRedirect, setOnLoginRedirect] = useState<
    OnLoginRedirect | undefined
  >(undefined);

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ["AuthContext_Query"],
    queryFn: () => graphql.AuthContext_Query(),
    enabled: !!firebase.user,
  });

  if (error) {
    Sentry.captureException(error);
  }

  const inSignInForm = router.asPath.includes("/signin");
  const inBookingForm = router.asPath.includes("/book");
  const user = data?.me;

  const signOut = () => {
    firebase.signOut();
    MarketingService.logEvent.logout({
      uid: user?.id.toString(),
      user_id: user?.id.toString(),
      user_status: "member",
      auth_method: "",
    });
    MarketingService.clearUserId();
    cookies().remove(COOKIE_TOKEN);
    cookies().remove(COOKIE_AUTHORIZATION);
    window.location.href = "/";
  };

  useEffect(() => {
    // logout if not an active user, and it's not in the SignInForm or BookingForm
    if (user && !user?.isActive && !inSignInForm && !inBookingForm) {
      firebase.signOut();
    }

    // redirect to top page, if active user and is in the SignInForme but not in BookingForm
    // two tabs opens problem
    if (user && user.isActive && inSignInForm && !inBookingForm) {
      router.push(ROUTES.home());
    }
  }, []);

  useEffect(() => {
    if (user?.isActive) {
      MarketingService.logEvent.brazeChangeUser({ userId: user.id });
      MarketingService.setUserId(user.id.toString());
      Sentry.setUser({ id: user.id });
      markLoginActivityMutation();
    }
  }, [user, markLoginActivityMutation]);

  const value = {
    firebase,
    refetch,
    user: user?.isActive ? user : undefined,
    userStatus: user?.status,
    signOut,
    onLoginRedirect: async () => {
      if (onLoginRedirect) {
        await onLoginRedirect();
      } else {
        router.push(ROUTES.home());
      }

      setTimeout(() => refetch(), 300);
    },
    setOnLoginRedirect,
    isLoading: firebase.isLoading || isLoading,
    routerPushIfAuthenticated: (path: string) => {
      if (user) {
        return router.push(path);
      } else {
        window.location.href = ROUTES.signin.index({
          redirectTo: router.basePath + path,
        });
        return Promise.resolve(true);
      }
    },
  };

  return (
    <AuthContext.Provider value={value} {...props}>
      {children}
    </AuthContext.Provider>
  );
};
