import RouterQueryUtils, {
  RouterQueryParams,
  RouterQueryInput,
  RouterQueryParam,
} from "@utils/router/routerQuery";
import {
  ExpediaHotelGradeFilter,
  ExpediaHotelOrderBy,
  ExpediaHotelRatingFilter,
  ExpediaHotelsAvailabilityFilterFeature,
  ExpediaPopularAmenityId,
  ExpediaPopularRoomAmenityId,
  ExpediaRoomBedCountId,
  FlightType,
  SeatClass,
  TimeOfDayFilter,
  TourFilterFeature,
  TourOrderBy,
  TravelDateFlexibility,
} from "@graphql/types";

export interface SearchPageQuery extends RouterQueryInput {
  highlightedTourId?: number;
  /** deprecated. please use destinationIds */
  destinationId?: number;
  destinationIds?: number[];
  airportId?: number;
  airportCode?: string;
  from?: string; // YYYY-MM-DD
  to?: string; // YYYY-MM-DD
  adults?: number;
  children?: number;
  children_without_bed?: number;
  infants?: number;
  rooms?: number;
  themeIds?: number[];
  grades?: number[];
  priceFrom?: number;
  priceTo?: number;
  flightTypes?: FlightType[];
  seatClass?: SeatClass[];
  orderBy?: TourOrderBy;
  features?: TourFilterFeature[];
  originDepartureTimes?: TimeOfDayFilter[];
  destinationDepartureTimes?: TimeOfDayFilter[];
  hotelsIds?: number[];
  travelDateFlexibility?: TravelDateFlexibility;
  airlineIds?: number[];
}

export interface HotelSearchPageQuery extends RouterQueryInput {
  checkInDate?: string;
  checkOutDate?: string;
  regionId?: number;
  adults?: number;
  children?: number;
  orderBy?: ExpediaHotelOrderBy;
  rating?: ExpediaHotelRatingFilter;
  grade?: ExpediaHotelGradeFilter[];
  features?: ExpediaHotelsAvailabilityFilterFeature[];
  freeCancel?: boolean;
  regionIds?: number[];
  priceFrom?: number;
  priceTo?: number;
  amenities?: ExpediaPopularAmenityId[];
  highlightedHotelId?: number;
  roomAmenityIds?: ExpediaPopularRoomAmenityId[];
  bedCounts?: ExpediaRoomBedCountId[];
}

const toFlightTypes = (param?: RouterQueryParam): FlightType[] | undefined => {
  const params =
    typeof param === "string" ? [param] : Array.isArray(param) ? param : [];
  const enumValues = Object.values(FlightType) as string[];
  return params.filter((v) => enumValues.includes(v)) as FlightType[];
};

const toSeatClass = (param?: RouterQueryParam): SeatClass[] | undefined => {
  const params =
    typeof param === "string" ? [param] : Array.isArray(param) ? param : [];
  const enumValues = Object.values(SeatClass) as string[];
  return params.filter((v) => enumValues.includes(v)) as SeatClass[];
};

const toFeatures = (
  param?: RouterQueryParam
): TourFilterFeature[] | undefined => {
  const params =
    typeof param === "string" ? [param] : Array.isArray(param) ? param : [];
  const enumValues = Object.values(TourFilterFeature) as string[];
  return params.filter((v) => enumValues.includes(v)) as TourFilterFeature[];
};

const toTimeOfDayFilter = (
  param?: RouterQueryParam
): TimeOfDayFilter[] | undefined => {
  const params =
    typeof param === "string" ? [param] : Array.isArray(param) ? param : [];
  const enumValues = Object.values(TimeOfDayFilter) as string[];
  return params.filter((v) => enumValues.includes(v)) as TimeOfDayFilter[];
};

export const toSearchPageQuery = (query: RouterQueryParams) => {
  const destinationId = RouterQueryUtils.getNumber(query.destinationId);
  const destinationIds = RouterQueryUtils.getArrayNumber(query.destinationIds);

  return {
    highlightedTourId: RouterQueryUtils.getNumber(query.highlightedTourId),
    /** deprecated. please use destinationIds */
    destinationId: RouterQueryUtils.getNumber(query.destinationId),
    destinationIds:
      destinationIds ?? (destinationId ? [destinationId] : undefined),
    airportId: RouterQueryUtils.getNumber(query.airportId),
    airportCode: RouterQueryUtils.getString(query.airportCode),
    from: RouterQueryUtils.getString(query.from || query.travelDateFrom),
    to: RouterQueryUtils.getString(query.to || query.travelDateTo),
    adults: RouterQueryUtils.getNumber(query.adults),
    children: RouterQueryUtils.getNumber(query.children),
    children_without_bed: RouterQueryUtils.getNumber(
      query.children_without_bed
    ),
    infants: RouterQueryUtils.getNumber(query.infants),
    rooms: RouterQueryUtils.getNumber(query.rooms),
    themeIds: RouterQueryUtils.getArrayNumber(query.themeIds),
    grades: RouterQueryUtils.getArrayNumber(query.grades),
    priceFrom: RouterQueryUtils.getNumber(query.priceFrom),
    priceTo: RouterQueryUtils.getNumber(query.priceTo),
    flightTypes: toFlightTypes(query.flightTypes),
    seatClass: toSeatClass(query.seatClass),
    features: toFeatures(query.features),
    orderBy: RouterQueryUtils.getString(query.orderBy) as
      | TourOrderBy
      | undefined,
    originDepartureTimes: toTimeOfDayFilter(query.originDepartureTimes),
    destinationDepartureTimes: toTimeOfDayFilter(
      query.destinationDepartureTimes
    ),
    hotelsIds: RouterQueryUtils.getArrayNumber(query.hotelsIds),
    travelDateFlexibility: RouterQueryUtils.getString(
      query.travelDateFlexibility
    ) as TravelDateFlexibility | undefined,
    airlineIds: RouterQueryUtils.getArrayNumber(query.airlineIds),
  };
};
