import { FC } from "react";
import Image from "next/image";
import { Button, Paragraph, Spacer } from "@newt/ui";
import { LINE_CONCIERGE_LINK } from "@constants";
import lineIcon from "./assets/icon_line.webp";
import Beach from "./assets/beach.webp";
import styles from "./LineConcierge.module.css";

interface Props {}

export const LineConcierge: FC<Props> = ({}) => {
  return (
    <>
      <div className={styles.flex}>
        <div>
          <Paragraph size={{ tablet: "lg" }}>
            海外旅行のことなら、どんなことでも！NEWTトラベルコンシェルジュにご相談ください🤝
          </Paragraph>
          <ul className={styles.list}>
            <li>
              <span>自分に合ったホテルを提案してほしい</span>
            </li>
            <li>
              <span>さまざまなエリアを周遊したい</span>
            </li>
            <li>
              <span>ハネムーンプランがあるか確認したい&nbsp;など</span>
            </li>
          </ul>
        </div>
        <div className={styles.imageWrapper}>
          <Image
            className={styles.image}
            src={Beach.src}
            alt="LINEで相談する"
            loading="lazy"
            fill={true}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <Spacer y={{ mobile: "6x", tablet: "10x" }} />
      <div className={styles.buttonWrapper}>
        <Button
          size="lg"
          variant="outline"
          href={LINE_CONCIERGE_LINK}
          target="_blank"
        >
          <div className={styles.buttonContent}>
            <Image
              src={lineIcon.src}
              alt="LINE"
              width={24}
              height={24}
              className={styles.lineIcon}
            />
            LINEで相談する
          </div>
        </Button>
      </div>
    </>
  );
};
