import { gtm } from "../gtm";

type ClickModuleId =
  | "search_from_hotel_navigationbar"
  | "item_filter_modal_chip"
  | "item_filter_chip"
  | "item_filter_submit_button"
  | "spin_gacha"
  | "spin_toa_gacha"
  | "phone_auth_button"
  | "google_auth_button"
  | "apple_auth_button";

export interface ClickModuleEventVariables {
  module_id: ClickModuleId;
  module_name: string;
  is_newt_app?: boolean;
}

export const clickModuleEvent = (variables?: ClickModuleEventVariables) => {
  gtm.pushEvent("click_module", variables);
};
