import { FC, useEffect, CSSProperties, useRef } from "react";
import styles from "./DateRangePicker.module.css";
import { ja } from "date-fns/locale";
import {
  DayContentProps,
  DayModifiers,
  DayPicker,
  Matcher,
  ModifiersClassNames,
  ModifiersStyles,
  SelectRangeEventHandler,
  CustomComponents,
  ClassNames,
  Formatters,
  DayPickerRangeProps,
} from "react-day-picker";
import { DateTime } from "luxon";
import { Icon } from "../Icon";
import dayPickerStyles from "react-day-picker/dist/style.module.css";
import { holidays } from "@newt/config/holidays";

interface DateColor {
  base: string;
  hover: string;
}

export const getDateColorFromLevel = (level?: number): DateColor => {
  switch (level) {
    case 1:
      return { base: "#E3F2FD", hover: "#BBDEFB" };
    case 2:
      return { base: "#EAF4D4", hover: "#D2ED85" };
    case 3:
      return { base: "#FFF9C4", hover: "#FFF176" };
    case 4:
      return { base: "#FFE9EE", hover: "#FFC8D1" };
    default:
      return { base: "#fff", hover: "#fff" };
  }
};

export interface DateRangePickerDateColor {
  level: number;
  date: Date;
  price?: string;
}

export interface DateRangePickerProps {
  from?: Date;
  to?: Date;
  initialMonth?: Date;
  minDate?: Date;
  maxDate?: Date;
  minSelectableDate?: Date;
  maxSelectableDate?: Date;
  isDisabledDay?: (date: Date) => boolean;
  onChange?: (from?: Date, to?: Date) => void;
  onMonthChange?: (month: Date) => void;
  dateColors?: DateRangePickerDateColor[];
  isScrollable: boolean;
}

const DAY_CUSTOM_MODIFIER_NAME = {
  PAST: "past",
  HIDDEN: "hidden",
  SELECTABLE: "selectable",
  CUSTOM_COLOR: "custom_color",
  RECOMMENDED_RANGE_TO: "recommended_range_to",
  SELECTED_RANGE_FROM: "selected_range_from",
  SELECTED_RANGE_TO: "selected_range_to",
  SELECTED_RANGE: "selected_range",
  HOLIDAY: "holiday",
};

const MODIFIERS_CLASS_NAMES: ModifiersClassNames = {
  [DAY_CUSTOM_MODIFIER_NAME.PAST]: styles.dayPast,
  [DAY_CUSTOM_MODIFIER_NAME.HIDDEN]: styles.dayHidden,
  [DAY_CUSTOM_MODIFIER_NAME.SELECTABLE]: styles.daySelectable,
  [DAY_CUSTOM_MODIFIER_NAME.CUSTOM_COLOR]: styles.dayCustomColor,
  [DAY_CUSTOM_MODIFIER_NAME.RECOMMENDED_RANGE_TO]: styles.dayRecommendedRangeTo,
  [DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE]: styles.daySelectedRange,
  [DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE_FROM]: styles.daySelectedRangeFrom,
  [DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE_TO]: styles.daySelectedRangeTo,
  [DAY_CUSTOM_MODIFIER_NAME.HOLIDAY]: styles.holiday,
};

const DayContent: FC<
  DayContentProps & { customColor?: DateColor; price?: string }
> = (props) => {
  const additionalStyles: CSSProperties = props.customColor
    ? ({
        ["--day-custom-color"]: props.customColor.base,
        ["--day-custom-color-hover"]: props.customColor.hover,
      } as CSSProperties)
    : {};

  return (
    <div className={styles.dayInner} style={additionalStyles}>
      <span className={styles.dayText}>{props.date.getDate()}</span>
      {!props.activeModifiers.disabled &&
        !props.activeModifiers.recommended_range_to && (
          <span className={styles.dayPrice}>{props.price}</span>
        )}
    </div>
  );
};

const isEqualYmd = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const DateRangePicker: FC<DateRangePickerProps> = ({
  from,
  to,
  minDate = DateTime.now().startOf("month").toJSDate(),
  maxDate = DateTime.now().plus({ year: 1 }).endOf("month").toJSDate(),
  minSelectableDate,
  maxSelectableDate,
  initialMonth,
  isDisabledDay,
  onChange,
  onMonthChange,
  dateColors = [],
  isScrollable,
}) => {
  if (minSelectableDate === undefined) minSelectableDate = minDate;
  if (maxSelectableDate === undefined) maxSelectableDate = maxDate;

  const ref = useRef<HTMLDivElement>(null);

  const components: CustomComponents = {
    DayContent: (props) => {
      const foundDate = dateColors.find((v) => isEqualYmd(v.date, props.date));
      return (
        <DayContent
          {...props}
          customColor={getDateColorFromLevel(foundDate?.level)}
          price={foundDate?.price}
        />
      );
    },
    IconRight: () => <Icon icon="chevronRight" />,
    IconLeft: () => <Icon icon="chevronLeft" />,
  };
  const classNames: ClassNames = {
    vhidden: dayPickerStyles.vhidden,
    root: styles.root,
    months: isScrollable ? styles.monthsScrollable : styles.months,
    month: styles.month,
    table: styles.table,
    caption: styles.caption,
    caption_label: styles.captionLabel,
    cell: styles.cell,
    head_cell: styles.headCell,
    day: styles.day,
    day_disabled: styles.dayDisabled,
    day_outside: styles.dayOutside,
    nav_button: styles.navBtn,
    nav_button_next: styles.navBtnNext,
    nav_button_previous: styles.navBtnPrev,
  };

  const formatters: Partial<Formatters> = {
    formatCaption: (date) => DateTime.fromJSDate(date).toFormat("yyyy年M月"),
  };

  const getDisabledDays = (): Matcher[] | undefined => {
    const modifiers: Matcher[] = [];

    if (isDisabledDay) modifiers.push((date) => isDisabledDay(date));
    if (minSelectableDate) {
      modifiers.push({ before: minSelectableDate });
    }
    if (maxSelectableDate) {
      modifiers.push({ after: maxSelectableDate });
    }

    return modifiers;
  };

  const adjustScrollTop = () => {
    const { current } = ref;
    if (current === null) return;

    const elRangeStart = current.querySelector(
      `.${styles.daySelectedRangeFrom}`
    );
    if (!elRangeStart) return;

    const elRangeStartMonth = elRangeStart.closest(`.${styles.month}`);
    if (!elRangeStartMonth) return;

    const wrapperRect = current.getBoundingClientRect();
    const targetRect = elRangeStartMonth.getBoundingClientRect();

    current.scrollTo({
      left: 0,
      top: targetRect.top - wrapperRect.top,
      behavior: "smooth",
    });
  };

  const getDayModifiers = (): DayModifiers | undefined => {
    const modifiers: DayModifiers = {};

    modifiers[DAY_CUSTOM_MODIFIER_NAME.HOLIDAY] = holidays;

    modifiers[DAY_CUSTOM_MODIFIER_NAME.SELECTABLE] = [
      {
        from: minDate,
        to: maxDate,
      },
      (date) => (isDisabledDay ? !isDisabledDay(date) : true),
    ];

    if (from) {
      modifiers[DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE_FROM] = from;

      if (to) {
        modifiers[DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE_TO] = to;
        modifiers[DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE] = {
          from: from,
          to: to,
        };
      } else {
        modifiers[DAY_CUSTOM_MODIFIER_NAME.RECOMMENDED_RANGE_TO] = {
          after: from,
          before: maxDate,
        };
      }
    }

    if (dateColors?.length) {
      modifiers[DAY_CUSTOM_MODIFIER_NAME.CUSTOM_COLOR] = dateColors.map(
        (v) => v.date
      );
    }

    if (isScrollable) {
      modifiers[DAY_CUSTOM_MODIFIER_NAME.HIDDEN] = {
        after: DateTime.fromJSDate(maxDate)
          .endOf("week")
          .plus({ day: -1 })
          .toJSDate(),
        before: DateTime.fromJSDate(minDate)
          .startOf("week")
          .plus({ day: -1 })
          .toJSDate(),
      };
    }

    modifiers[DAY_CUSTOM_MODIFIER_NAME.PAST] = {
      before: minDate,
    };
    return modifiers;
  };

  const getModifiersStyles = () => {
    const modifiers: ModifiersStyles = {
      [DAY_CUSTOM_MODIFIER_NAME.CUSTOM_COLOR]: {},
    };
    return modifiers;
  };

  const onSelect: SelectRangeEventHandler = (range) => {
    if (onChange) onChange(range?.from, range?.to);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isScrollable) {
        adjustScrollTop();
      }
    }, 200);
  }, [isScrollable]);

  const dayPickerProps: DayPickerRangeProps = {
    locale: ja,
    mode: "range",
    className: styles.root,
    classNames,
    formatters,
    disabled: getDisabledDays(),
    modifiers: getDayModifiers(),
    modifiersStyles: getModifiersStyles(),
    modifiersClassNames: MODIFIERS_CLASS_NAMES,
    fromDate: minDate,
    toDate: maxDate,
    selected: { from, to },
    components,
    onMonthChange,
    onSelect,
  };
  if (isScrollable) {
    dayPickerProps.numberOfMonths = Math.ceil(
      DateTime.fromJSDate(maxDate)
        .diff(DateTime.fromJSDate(minDate))
        .as("month")
    );
    // scrollableの時はUI上でmonthの変更は無いため、minDateでmonthを固定する。
    // monthを指定しないと、dayPicker側で `selected.from` の値になり、`selected.from` より前の日付が表示されなくなる
    dayPickerProps.month = minDate;
  } else {
    dayPickerProps.defaultMonth = from || initialMonth || minDate;
    dayPickerProps.numberOfMonths = 2;
    dayPickerProps.pagedNavigation = true;
  }

  return (
    <div
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      className={[styles.wrapper, isScrollable ? styles.scrollable : ""].join(
        " "
      )}
    >
      <DayPicker {...dayPickerProps} />
    </div>
  );
};
