import { HotelDetailOnSitePayments_HotelFragment } from "@graphql/types";
import { PaymentIcon, PaymentIconType } from "@newt/ui";
import { gql } from "graphql-request";
import { FC } from "react";
import styles from "./HotelDetailOnSitePayments.module.css";

const HOTEL_DETAIL_ON_SITE_PAYMENTS_FRAGMENT = gql`
  fragment HotelDetailOnSitePayments_Hotel on ExpediaHotel {
    onSitePayments {
      creditCards {
        id
        name
        brand
      }
    }
  }
`;

interface HotelDetailOnSitePaymentsProps {
  fragment: HotelDetailOnSitePayments_HotelFragment;
}

export const HotelDetailOnSitePayments: FC<HotelDetailOnSitePaymentsProps> = ({
  fragment,
}) => {
  if (
    !fragment.onSitePayments ||
    fragment.onSitePayments.creditCards.length == 0
  )
    return null;

  return (
    <div className={styles.description}>
      <div className={styles.descriptionTitle}>
        ホテルで利用できるクレジットカード
      </div>
      <div className={styles.descriptionContent}>
        <div className={styles.onSitePayment}>
          {fragment.onSitePayments.creditCards.map((creditCard) => {
            if (creditCard.brand) {
              return (
                <PaymentIcon
                  key={creditCard.id}
                  icon={creditCard.brand as PaymentIconType}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
