import { FC } from "react";
import { gql } from "graphql-request";
import { Profile, Text } from "@newt/ui";
import styles from "./CommentsWithProfile.module.css";
import { CommentsWithProfile_MicroCMSCommentFragment } from "@graphql/types";

const COMMENTS_BY_PERSON = gql`
  fragment CommentsWithProfile_MicroCMSComment on MicroCMSComment {
    comment
    person {
      name
      title
      description
      icon {
        width
        url
        height
      }
    }
  }
`;

interface Props {
  comments: CommentsWithProfile_MicroCMSCommentFragment[];
  showDescription?: boolean;
}

export const CommentsWithProfile: FC<Props> = (props) => {
  const { comments, showDescription } = props;

  const rootClassName = [
    styles.root,
    comments?.length === 1 ? styles.fullWidth : "",
  ].join(" ");

  return (
    <section className={rootClassName}>
      {comments.map((comment, index) => {
        return (
          <div key={index} className={styles.subgrid}>
            <div>
              <Profile {...comment.person} showDescription={showDescription} />
            </div>
            <Text size={"base"} color={"black"}>
              {comment.comment}
            </Text>
          </div>
        );
      })}
    </section>
  );
};
