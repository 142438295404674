import { CreateForm, stripNulls } from "@utils/form";
import * as yup from "yup";
import { useRouter } from "next/router";
import { ROUTES } from "@routes";
import { SearchPageQuery } from "@components/pages/search/query";
import RouterQueryUtils from "@utils/router/routerQuery";
import {
  FlightType,
  SeatClass,
  TimeOfDayFilter,
  TourFilterFeature,
  TourOrderBy,
  TravelDateFlexibility,
} from "@graphql/types";
import { useEffect } from "react";
import { SearchInitialData, useSearchData } from "./useSearchData";

export const SEARCH_FORM_PRICE_MIN = 0;
export const SEARCH_FORM_PRICE_MAX = 1000000;

export const SEARCH_FORM_PRICES = [
  SEARCH_FORM_PRICE_MIN,
  50000,
  60000,
  70000,
  80000,
  90000,
  100000,
  110000,
  120000,
  130000,
  140000,
  150000,
  200000,
  250000,
  300000,
  350000,
  400000,
  450000,
  500000,
  750000,
  SEARCH_FORM_PRICE_MAX,
] as const;

export interface SearchFormValues {
  destinationIds: number[];
  airportId: string;
  from: string; // YYYY-MM-DD
  to: string; // YYYY-MM-DD
  adults: number;
  children: number;
  children_without_bed: number;
  infants: number;
  rooms: number;
  grades: number[];
  priceFrom: number;
  priceTo: number;
  flightTypes: FlightType[];
  seatClass: SeatClass[];
  orderBy: TourOrderBy;
  themeIds: number[];
  features: TourFilterFeature[];
  highlightedTourId: string;
  originDepartureTimes: TimeOfDayFilter[];
  destinationDepartureTimes: TimeOfDayFilter[];
  hotelsIds: number[];
  travelDateFlexibility: TravelDateFlexibility;
  airlineIds: number[];
}

export const DEFAULT_SEARCH_FORM_VALUES: SearchFormValues = {
  destinationIds: [],
  airportId: "",
  from: "",
  to: "",
  adults: 2,
  children: 0,
  children_without_bed: 0,
  infants: 0,
  rooms: 1,
  priceFrom: SEARCH_FORM_PRICES[0],
  priceTo: SEARCH_FORM_PRICES[SEARCH_FORM_PRICES.length - 1],
  grades: [],
  flightTypes: [],
  seatClass: [],
  orderBy: TourOrderBy.RECOMMENDED,
  themeIds: [],
  features: [],
  highlightedTourId: "",
  originDepartureTimes: [],
  destinationDepartureTimes: [],
  hotelsIds: [],
  travelDateFlexibility: TravelDateFlexibility.FIXED,
  airlineIds: [],
};

export const searchFormValuesToQuery = (
  values: SearchFormValues
): SearchPageQuery => {
  return {
    highlightedTourId: values.highlightedTourId
      ? Number(values.highlightedTourId)
      : undefined,
    adults: values.adults,
    infants: values.infants,
    rooms: values.rooms,
    children: values.children,
    children_without_bed: values.children_without_bed,
    destinationIds: values.destinationIds,
    airportId: values.airportId ? Number(values.airportId) : undefined,
    from: values.from,
    to: values.to,
    grades: values.grades,
    priceFrom:
      values.priceFrom !== SEARCH_FORM_PRICE_MIN ? values.priceFrom : undefined,
    priceTo:
      values.priceTo !== SEARCH_FORM_PRICE_MAX ? values.priceTo : undefined,
    flightTypes: values.flightTypes,
    seatClass: values.seatClass,
    themeIds: values.themeIds,
    features: values.features,
    orderBy: values.orderBy,
    originDepartureTimes: values.originDepartureTimes,
    destinationDepartureTimes: values.destinationDepartureTimes,
    hotelsIds: values.hotelsIds,
    travelDateFlexibility:
      values.from && values.to
        ? values.travelDateFlexibility
        : TravelDateFlexibility.FIXED,
    airlineIds: values.airlineIds,
  };
};

export const useSearchForm = (
  baseQuery?: SearchPageQuery,
  initialData?: SearchInitialData,
  options?: { isOpenInNewTab: boolean }
) => {
  const router = useRouter();

  const defaultQuery = {
    airportCode: "TYO",
    highlightedTourId: router.query.tourId,
  };
  const { airports, isLoading } = useSearchData(initialData);
  const query = { ...defaultQuery, ...stripNulls(baseQuery) };

  const getDestinationIds = (): number[] => {
    return query.destinationIds && query.destinationIds.length > 0
      ? query.destinationIds
      : query.destinationId
        ? [query.destinationId]
        : DEFAULT_SEARCH_FORM_VALUES.destinationIds;
  };

  const onSubmit = async (values: SearchFormValues) => {
    const initDestinationIds = getDestinationIds();

    (Object.keys(values) as Array<keyof SearchFormValues>).forEach((key) => {
      if (values[key] === undefined || values[key] === "") delete values[key];
    });
    let newQuery = searchFormValuesToQuery(values);
    const isDestinationChanged =
      JSON.stringify(initDestinationIds) !==
      JSON.stringify(newQuery.destinationIds);
    const isAirportChanged = query.airportId !== newQuery.airportId;

    // もし出発日が未入力の場合は、ゆらぎ検索をしない
    if (!(newQuery.from && newQuery.to)) {
      newQuery = {
        ...newQuery,
        travelDateFlexibility: DEFAULT_SEARCH_FORM_VALUES.travelDateFlexibility,
      };
    }

    // reset hotelsIds if destinationIds are changed (順番も変わってたらresetする)
    if (isDestinationChanged) {
      newQuery = { ...newQuery, hotelsIds: undefined };
    }

    // reset airlineIds if airportId is changed
    if (isDestinationChanged || isAirportChanged) {
      newQuery = { ...newQuery, airlineIds: undefined };
    }

    options?.isOpenInNewTab
      ? window.open(ROUTES.search(newQuery))
      : await router.push(ROUTES.search(newQuery), undefined, {
          scroll: false,
        });
  };

  const getAirportId = (): string => {
    return (
      query?.airportId?.toString() ??
      airports.public
        ?.find((v) => v.code === query?.airportCode)
        ?.id.toString() ??
      DEFAULT_SEARCH_FORM_VALUES.airportId
    );
  };

  const getDefaultValues = (): SearchFormValues => {
    return {
      destinationIds: getDestinationIds(),
      airportId: getAirportId(),
      from: query?.from ?? DEFAULT_SEARCH_FORM_VALUES.from,
      to: query?.to ?? DEFAULT_SEARCH_FORM_VALUES.to,
      adults: query?.adults ?? DEFAULT_SEARCH_FORM_VALUES.adults,
      children: query?.children ?? DEFAULT_SEARCH_FORM_VALUES.children,
      children_without_bed:
        query?.children_without_bed ??
        DEFAULT_SEARCH_FORM_VALUES.children_without_bed,
      infants: query?.infants ?? DEFAULT_SEARCH_FORM_VALUES.infants,
      rooms: query?.rooms ?? DEFAULT_SEARCH_FORM_VALUES.rooms,
      grades: query?.grades ?? [],
      priceFrom: query?.priceFrom ?? DEFAULT_SEARCH_FORM_VALUES.priceFrom,
      priceTo: query?.priceTo ?? DEFAULT_SEARCH_FORM_VALUES.priceTo,
      flightTypes: query?.flightTypes ?? DEFAULT_SEARCH_FORM_VALUES.flightTypes,
      seatClass: query?.seatClass ?? DEFAULT_SEARCH_FORM_VALUES.seatClass,
      orderBy: query?.orderBy ?? DEFAULT_SEARCH_FORM_VALUES.orderBy,
      themeIds: query?.themeIds ?? DEFAULT_SEARCH_FORM_VALUES.themeIds,
      features: query?.features ?? DEFAULT_SEARCH_FORM_VALUES.features,
      highlightedTourId:
        query?.highlightedTourId?.toString() ??
        DEFAULT_SEARCH_FORM_VALUES.highlightedTourId,
      originDepartureTimes:
        query?.originDepartureTimes ??
        DEFAULT_SEARCH_FORM_VALUES.originDepartureTimes,
      destinationDepartureTimes:
        query?.destinationDepartureTimes ??
        DEFAULT_SEARCH_FORM_VALUES.destinationDepartureTimes,
      hotelsIds: query?.hotelsIds ?? DEFAULT_SEARCH_FORM_VALUES.hotelsIds,
      travelDateFlexibility:
        query.from && query.to && query.travelDateFlexibility
          ? query.travelDateFlexibility
          : DEFAULT_SEARCH_FORM_VALUES.travelDateFlexibility,
      airlineIds: query?.airlineIds ?? DEFAULT_SEARCH_FORM_VALUES.airlineIds,
    };
  };

  const form = CreateForm<SearchFormValues>({
    defaultValues: getDefaultValues(),
    rules: {
      destinationIds: yup.array().of(yup.number()),
      airportId: yup.string(),
      from: yup.string(),
      to: yup.string(),
      adults: yup.number(),
      children: yup.number(),
      children_without_bed: yup.number(),
      infants: yup.number(),
      rooms: yup.number(),
      grades: yup.array(),
      priceFrom: yup.number(),
      priceTo: yup.number(),
      flightTypes: yup.array(),
      seatClass: yup.array(),
      orderBy: yup.string(),
      themeIds: yup.array(),
      features: yup.array(),
      highlightedTourId: yup.string(),
      originDepartureTimes: yup.array(),
      destinationDepartureTimes: yup.array(),
      hotelsIds: yup.array(),
      travelDateFlexibility: yup.string(),
      airlineIds: yup.array(),
    },
    onSubmit,
  });

  useEffect(() => {
    const values = getDefaultValues();
    Object.keys(values).forEach((key) => {
      const keyName = key as keyof SearchFormValues;
      form.setValue(keyName, values[keyName] as any);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, isLoading]);

  return form;
};
