import { useIsWebview } from "@utils/webview";
import { ResponsiveProp, Spacer, Stack, Tag, Text } from "@newt/ui";
import styles from "./HotelCardPrice.module.css";
import { HotelCardPrice_ExpediaRateFragment } from "@graphql/types";
import { PointsDisplay } from "../PointsDisplay";
import { gql } from "graphql-request";
import { useAuth } from "@utils/auth";

const HOTEL_CARD_PRICE_FRAGMENT = gql`
  fragment HotelCardPrice_ExpediaRate on ExpediaRate {
    isMemberDeal
    pricing {
      items {
        id
        value
      }
      strikethrough {
        value
        displayPrice
      }
      totalNightsFees {
        displayPrice
        description
      }
    }
    numberOfNights
    availableRooms
    ...PointsDisplay_ExpediaRate
  }
`;

interface HotelCardPriceProps {
  rate: HotelCardPrice_ExpediaRateFragment;
  isLoggedIn: boolean;
  justify?: ResponsiveProp<"end" | "center" | "start" | "between">; // TODO: support text alignment as well (<Text>)
  isTotalPriceLabelVisible?: boolean;
}

export const HotelCardPrice = ({
  rate,
  isLoggedIn,
  justify,
}: HotelCardPriceProps) => {
  const isWebview = useIsWebview(); // TODO: use COOKIE_APP_ID ?

  return (
    <div className={styles.root}>
      {rate.isMemberDeal ? (
        isWebview || isLoggedIn ? (
          <>
            <Stack as="div" align="center" justify={justify}>
              <Tag variant="solid" color="positiveRed">
                会員割引
              </Tag>
              <Text color="positiveRed" weight="bold" size="base" lineThrough>
                {rate.pricing.strikethrough?.displayPrice}
              </Text>
            </Stack>
            <Spacer y="2x" />
          </>
        ) : (
          <>
            <Tag variant="solid" color="positiveRed">
              会員登録でもっとおトク
            </Tag>
            <Spacer y="2x" />
          </>
        )
      ) : (
        rate.pricing.strikethrough && (
          <Stack as="div" justify={justify}>
            <Text color="positiveRed" weight="bold" size="base" lineThrough>
              {rate.pricing.strikethrough.displayPrice}
            </Text>
          </Stack>
        )
      )}
      <Stack as="div" align="baseline" justify={justify}>
        <Text size="sm" weight="bold" color="gray">
          {`${rate.numberOfNights}泊`}
        </Text>
        <Text size="2xl" weight="bold">
          {rate.pricing.totalNightsFees.displayPrice}
        </Text>
      </Stack>
      <Spacer y="base" />
      <div className={styles.displayPriceDescription}>
        {rate.pricing.totalNightsFees.description}
      </div>
      {rate.points && (
        <>
          <Spacer y="base" />
          <PointsDisplay
            points={rate.points}
            pointRange={rate.pointRange}
            justify={justify}
          />
        </>
      )}
    </div>
  );
};
