import { FC, useEffect } from "react";
import { alert } from "@newt/ui";
import { useAuth } from "@utils/auth";
import { graphql } from "@utils/graphql";
import { gql } from "graphql-request";
import { useRouter } from "next/router";
import { ROUTES } from "@routes";
import { useIsWebview } from "@utils/webview";
import { TermsOfServiceType } from "@graphql/types";
import { useMutation } from "@tanstack/react-query";

const CONSENT_MODAL_USER = gql`
  fragment ConsentModal_User on User {
    unAgreedTermsOfService {
      termsOfServices {
        id
        type
      }
      title
      message
    }
  }
`;

export const CONSENT_MODAL_AGREE_TERMS_OF_SERVICES = gql`
  mutation ConsentModal_AgreeTermsOfServices(
    $input: AgreeTermsOfServicesInput!
  ) {
    agreeTermsOfServices(input: $input) {
      isOk
    }
  }
`;

export const ConsentModal: FC = () => {
  const auth = useAuth();
  const router = useRouter();
  const isNotApplicablePage =
    router.pathname === ROUTES.terms() ||
    router.pathname === ROUTES.privacy() ||
    router.pathname === ROUTES.privacyOverseas();
  const isWebview = useIsWebview();
  const unAgreedTermsOfService = auth.user?.unAgreedTermsOfService;
  const hasRevisionTermsOfUse = unAgreedTermsOfService?.termsOfServices.some(
    (term) => term.type === TermsOfServiceType.TERMS_OF_USE
  );
  const hasRevisionPrivacyPolicy = unAgreedTermsOfService?.termsOfServices.some(
    (term) => term.type === TermsOfServiceType.PRIVACY_POLICY
  );

  const mutation = useMutation({
    mutationFn: (termsOfServiceIds: number[]) =>
      graphql.ConsentModal_AgreeTermsOfServices({
        input: {
          termsOfServiceIds,
        },
      }),
  });

  const handleAccept = () =>
    mutation.mutate(
      unAgreedTermsOfService?.termsOfServices.map((term) => term.id) || []
    );

  useEffect(() => {
    if (
      router.isReady &&
      unAgreedTermsOfService?.termsOfServices.length &&
      !isNotApplicablePage &&
      !isWebview
    ) {
      alert(
        <>
          {unAgreedTermsOfService.message}
          <div style={{ margin: "4px 0 0 0" }}>
            {hasRevisionTermsOfUse && (
              <a href={ROUTES.terms()} target="_blank">
                利用規約をみる
              </a>
            )}
          </div>
          <div style={{ margin: "4px 0 0 0" }}>
            {hasRevisionPrivacyPolicy && (
              <a href={ROUTES.privacy()} target="_blank">
                プライバシーポリシーをみる
              </a>
            )}
          </div>
        </>,
        {
          hideClose: true,
          title: unAgreedTermsOfService.title,
          acceptLabel: "同意してつづける",
          onAccept: handleAccept,
        }
      );
    }
  }, [auth.user, isNotApplicablePage, isWebview]);

  return <></>;
};
