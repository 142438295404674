import { useRouter } from "next/router";
import { useContext } from "react";
import { WebviewContext, ContextProps } from "../context/WebViewContext";

export function useIsWebview() {
  const { isWebview } = useContext<ContextProps>(WebviewContext);

  const { pathname } = useRouter();
  return pathname?.startsWith("/webview/") || isWebview;
}
