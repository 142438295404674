import styles from "./Carousel.module.css";
import { FC, TransitionEventHandler, useEffect, useRef, useState } from "react";
import { SwipeDirections, SwipeEventData, useSwipeable } from "react-swipeable";
import { useInterval } from "../../hooks/useInterval";
import { LazyImage } from "../LazyImage";

export type CarouselImage = {
  src: string;
  alt: string;
};

interface CarouselProps {
  images: CarouselImage[];
  aspectRatio?: number;
  swipeThreshold?: number;
  auto?: boolean;
  interval?: number;
  lazyLoad?: boolean; // must be loaded lazily on modal. HT-737
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  maxImageCount?: number;
}

export const Carousel: FC<CarouselProps> = ({
  aspectRatio = 9 / 16,
  swipeThreshold = 80,
  images,
  auto = true,
  interval = 3000,
  lazyLoad,
  onError,
  maxImageCount,
}) => {
  const ENABLED_SWIPE_DIRECTIONS: SwipeDirections[] = ["Left", "Right"];
  const INTERVAL_TICK_MS = 100;

  const handlers = useSwipeable({
    onSwiped: (eventData) => handleSwiped(eventData),
    onSwiping: (eventData) => handleSwiping(eventData),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });
  const rootEl = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [animatingSlide, setAnimatingSlide] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [movedX, setMovedX] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [remainingMs, setRemainingMs] = useState(interval);

  useInterval(() => {
    if (!auto) return;
    if (remainingMs <= 0) {
      slide(activeIndex + 1);
    } else {
      setRemainingMs((ms) => ms - INTERVAL_TICK_MS);
    }
  }, INTERVAL_TICK_MS);

  const slides = images.map((v, index) => ({ ...v, no: index + 1 }));

  const firstImage = slides[0];
  const lastImage = slides[slides.length - 1];
  slides.push(firstImage);
  slides.unshift(lastImage);
  const firstImageIndex = 1;
  const lastImageIndex = slides.length - 2;

  if (!slides[activeIndex]) {
    window.location.reload();
  }

  const activeNo = slides[activeIndex] ? slides[activeIndex].no : 1;

  const slidedWidth = imageWidth * activeIndex;

  const listClasses = [
    styles.list,
    animatingSlide ? styles["is-animate"] : "",
  ].join(" ");

  const slide = (index: number, isAnimating = true) => {
    if (isAnimating) setAnimatingSlide(true);

    setActiveIndex(index);
    setMovedX(0);

    if (remainingMs !== interval) setRemainingMs(interval);
  };

  const handleSwiping = (eventData: SwipeEventData) => {
    if (eventData.first || !ENABLED_SWIPE_DIRECTIONS.includes(eventData.dir)) {
      return;
    }
    setMovedX(eventData.deltaX);
  };

  const handleSwiped = (eventData: SwipeEventData) => {
    if (animatingSlide) return;

    const { deltaX } = eventData;

    const isNext = deltaX < 0;
    if (
      !ENABLED_SWIPE_DIRECTIONS.includes(eventData.dir) ||
      deltaX === 0 ||
      (isNext && deltaX > swipeThreshold * -1) ||
      (!isNext && deltaX < swipeThreshold)
    ) {
      setAnimatingSlide(true);
      setMovedX(0);
      return;
    }
    slide(isNext ? activeIndex + 1 : activeIndex - 1);
  };

  const handleTransitionEndList: TransitionEventHandler = (event) => {
    setAnimatingSlide(false);

    if (activeIndex === 0) {
      slide(lastImageIndex, false);
    } else if (activeIndex === slides.length - 1) {
      slide(firstImageIndex, false);
    }
  };

  useEffect(() => {
    const setWidth = () => {
      if (rootEl.current) {
        setImageWidth(rootEl.current.getBoundingClientRect().width);
        setIsLoaded(true);
      }
    };
    lazyLoad ? setTimeout(() => setWidth(), 300) : setWidth();
    window.addEventListener("resize", setWidth);
  }, [lazyLoad]);

  return (
    <div
      ref={rootEl}
      style={{ paddingTop: `${aspectRatio * 100}%` }}
      className={styles.root}
    >
      {isLoaded && (
        <div {...handlers} className={styles.inner}>
          <div
            className={listClasses}
            style={{ transform: `translateX(-${slidedWidth - movedX}px)` }}
            onTransitionEnd={handleTransitionEndList}
          >
            {slides.map((v, index) => (
              <div
                key={index}
                className={`${styles.image} ${
                  index === activeIndex ? "is-active" : ""
                }`}
                style={{
                  minWidth: `${imageWidth}px`,
                }}
              >
                <LazyImage
                  src={v.src}
                  alt={v.alt}
                  aspectRatio={`1 / ${aspectRatio}`}
                  onError={onError}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.counter}>
        {activeNo}/{maxImageCount || images.length}
      </div>
    </div>
  );
};
