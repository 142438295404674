import { HotelAssignmentType, TourHistoryCardFragment } from "@graphql/types";
import { TourHistoryCardModel } from "@newt/ui";
import { ROUTES, withQuery } from "@routes";
import { ItemListId } from "@utils/marketing/events/viewItemListEvent";
import { getFlightTimeOfDay } from "@utils/presenter/getFlightTime";
import { getFlightType } from "@utils/presenter/getFlightType";
import { getTourPoint, getTourPrice } from "@utils/presenter/getTourPrice";

export const toTourHistoryCardModel = (
  v: TourHistoryCardFragment,
  itemListId: ItemListId
): TourHistoryCardModel => {
  const sortedPlans = v.plans.sort((n, p) =>
    n.travelDays > p.travelDays ? 1 : -1
  );

  const getTravelDays = () => {
    if (sortedPlans.length == 1) {
      return `${sortedPlans[0].travelDays}日間`;
    }

    return `${sortedPlans[0].travelDays}〜${
      sortedPlans[sortedPlans.length - 1].travelDays
    }日間`;
  };

  const firstPlan = v.plans[0];

  const hotel = v.hotel?.name;
  const hotelRating = v.hotel?.grade;

  const query = {
    highlightedTourId: v.id,
    destinationIds: v.destinations.map((d) => d.id),
    airportId: v.plans[0].airPlan.departureFlights[0].departureAirport.id,
    itemListId,
  };

  return {
    id: v.id,
    title: v.title,
    href: v.isUnlisted ? v.url : ROUTES.search(query),
    imageUrl: v.mainImage?.url ?? null,
    priceCaption: v.price
      ? `おとな1名・${sortedPlans[0].travelDays}日間（燃油込み）`
      : "",
    price: getTourPrice(v.price),
    point: getTourPoint(v.price),
    themes: v.themes,
    location: [v.destinationDisplay, getTravelDays()].join(" / "),
    hotel: hotel ?? "",
    hotelCount: v.hotelCount,
    hotelRating: hotelRating ?? 0,
    flightType: v.multiDestination
      ? "周遊プラン"
      : getFlightType(firstPlan.airPlan.flightType),
    airDestinationTime: getFlightTimeOfDay(
      firstPlan.airPlan.destinationDepartureTime
    ),
    airOriginTime: getFlightTimeOfDay(firstPlan.airPlan.originDepartureTime),
    onSale: v.onSale,
  };
};
