import { FC } from "react";
import { gql } from "graphql-request";
import { Icon, ResponsiveProp, Stack, Text } from "@newt/ui";
import Link from "next/link";
import styles from "./PointsDisplay.module.css";
import { ROUTES } from "@newt/core";
import {
  PointsDisplay_ExpediaRateFragment,
  ExpediaPointRange,
} from "@graphql/types";
import { formatNumber } from "@utils/formatNumber";

// TODO: define as fragment like { fragment: PointDisplayFragment }
type PointsDisplayProps = PointsDisplay_ExpediaRateFragment & {
  justify?: ResponsiveProp<"end" | "center" | "start" | "between">;
  hasInfo?: boolean;
};

export const PointsDisplay: FC<PointsDisplayProps> = ({
  points,
  pointRange,
  justify,
  hasInfo,
}) => {
  return (
    <Stack as="div" align="center" justify={justify}>
      <div className={styles.pointIcon}>
        <Icon icon="point" size="sm" color="green-40" />
      </div>
      <Text size="sm" weight="bold">
        {`${formatNumber(points)}ポイント`}
        {pointRange === ExpediaPointRange.FROM && "～"}
      </Text>
      <Text size="sm">たまる</Text>
      {hasInfo && (
        <Link
          href={ROUTES.point()}
          target="_blank"
          className={styles.pointAlertIcon}
        >
          <Icon icon="alertCircleOutline" size="sm" />
        </Link>
      )}
    </Stack>
  );
};

gql`
  fragment PointsDisplay_ExpediaRate on ExpediaRate {
    pointRange
    points
  }
`;
