import { useFetchMyPageReferralCampaign } from "@components/pages/account/Account/useFetchMyPageReferralCampaign";
import { Button, Icon, Spacer, Stack, Text, useBreakPoints } from "@newt/ui";
import { Dropdown } from "@newt/ui/src/components/Dropdown";
import { DropdownGroup } from "@newt/ui/src/components/Dropdown/components/DropdownGroup";
import { DropdownItem } from "@newt/ui/src/components/Dropdown/components/DropdownItem";
import { ROUTES } from "@routes";
import { useAuth } from "@utils/auth";
import { UserPoint } from "@newt/core/src/components";
import Link from "next/link";
import { FC } from "react";
import styles from "./User.module.css";

type UserProps = {
  transparent?: boolean;
};

export const User: FC<UserProps> = ({ transparent = false }) => {
  const auth = useAuth();
  const { myPageReferralCampaign } = useFetchMyPageReferralCampaign();

  const breakPoints = useBreakPoints();

  if (auth.isLoading) {
    return null;
  }

  if (!auth.user) {
    return breakPoints.laptopWithUp ? (
      <Button
        href={ROUTES.signin.index()}
        as="a"
        variant="solid"
        color="primary"
        size="sm"
        data-testid="header-login-button"
      >
        ログイン / 新規登録
      </Button>
    ) : null;
  }

  const DropdownTrigger = (
    <button className={`${styles.root}`} data-testid="header-user-dropdown">
      <figure className={styles.avatar}>
        <Icon size="sm" icon="person" color="white" />
      </figure>
      <strong className={styles.body}>
        <Text
          weight="bold"
          color={transparent ? "white" : "black"}
          shadowed={transparent}
        >
          {auth.user.firstName || auth.user.displayName}
        </Text>
      </strong>
      <figure className={styles.arrow}>
        <Icon icon="arrowDropdown" color={transparent ? "white" : "gray-80"} />
      </figure>
    </button>
  );

  return (
    <Dropdown
      triggerElement={DropdownTrigger}
      size="sm"
      position="bottom-right"
      classNames={{ container: styles.dropdownContainer }}
    >
      <DropdownGroup>
        <Stack direction="column" spacing="2">
          <Text weight="bold" size="lg">
            {auth.user.displayName}
          </Text>
          {auth.user.customerId && <Text>会員ID：{auth.user.customerId}</Text>}
        </Stack>
        <Spacer y="3x" />
        <UserPoint points={auth.user.points} />
        <Link href={ROUTES.reservations.index()} passHref legacyBehavior>
          <DropdownItem icon="travel">予約の確認</DropdownItem>
        </Link>
      </DropdownGroup>
      <DropdownGroup>
        <Link href={ROUTES.account.profile.index()} passHref legacyBehavior>
          <DropdownItem icon="personOutline">アカウント情報</DropdownItem>
        </Link>
        <Link
          href={ROUTES.account.paymentMethod.index()}
          passHref
          legacyBehavior
        >
          <DropdownItem icon="creditCard">お支払い方法</DropdownItem>
        </Link>
        <Link href={ROUTES.account.wishlist()} passHref legacyBehavior>
          <DropdownItem icon="heartOutline">お気にいり</DropdownItem>
        </Link>
        <Link href={ROUTES.account.coupons()} passHref legacyBehavior>
          <DropdownItem icon="coupon">保有クーポン</DropdownItem>
        </Link>
        {!!myPageReferralCampaign && (
          <Link href={ROUTES.friendCode()} passHref legacyBehavior>
            <DropdownItem icon="gift">{`紹介して${myPageReferralCampaign.pointAmount.toLocaleString()}ポイントGET`}</DropdownItem>
          </Link>
        )}
        <Link href={ROUTES.account.departureAirport()} passHref legacyBehavior>
          <DropdownItem icon="flightTakeoff">利用する空港</DropdownItem>
        </Link>
      </DropdownGroup>
      <DropdownGroup>
        <DropdownItem icon="helpCircleOutline" href={ROUTES.help()}>
          ヘルプセンター
        </DropdownItem>
        <DropdownItem
          icon="logout"
          onClick={auth.signOut}
          data-testid="header-user-logout"
        >
          ログアウト
        </DropdownItem>
      </DropdownGroup>
    </Dropdown>
  );
};
