import { FC, MouseEventHandler } from "react";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import { ResponsiveProp } from "../../types/component.type";
import styles from "./Icon.module.css";
import { Icon24Hours } from "./icons/Icon24Hours";
import { IconAccessibility } from "./icons/IconAccessibility";
import { IconAccountCircle } from "./icons/IconAccountCircle";
import { IconAdd } from "./icons/IconAdd";
import { IconAddCircle } from "./icons/IconAddCircle";
import { IconAddCircleOutline } from "./icons/IconAddCircleOutline";
import { IconAirConditioning } from "./icons/IconAirConditioning";
import { IconAirport } from "./icons/IconAirport";
import { IconAirportTransportation } from "./icons/IconAirportTransportation";
import { IconAlarmOutline } from "./icons/IconAlarmOutline";
import { IconAlertCircle } from "./icons/IconAlertCircle";
import { IconAlertCircleOutline } from "./icons/IconAlertCircleOutline";
import { IconArrowDown } from "./icons/IconArrowDown";
import { IconArrowDropDown } from "./icons/IconArrowDropDown";
import { IconArrowDropLeft } from "./icons/IconArrowDropLeft";
import { IconArrowDropRight } from "./icons/IconArrowDropRight";
import { IconArrowDropUp } from "./icons/IconArrowDropUp";
import { IconArrowLeft } from "./icons/IconArrowLeft";
import { IconArrowRight } from "./icons/IconArrowRight";
import { IconArrowUp } from "./icons/IconArrowUp";
import { IconBathtub } from "./icons/IconBathtub";
import { IconBedOutline } from "./icons/IconBedOutline";
import { IconBedSingle } from "./icons/IconBedSingle";
import { IconBlock } from "./icons/IconBlock";
import { IconBreakfast } from "./icons/IconBreakfast";
import { IconBusStation } from "./icons/IconBusStation";
import { IconCall } from "./icons/IconCall";
import { IconCamera } from "./icons/IconCamera";
import { IconCar } from "./icons/IconCar";
import { IconCasino } from "./icons/IconCasino";
import { IconCheck } from "./icons/IconCheck";
import { IconCheckCircle } from "./icons/IconCheckCircle";
import { IconChevronDown } from "./icons/IconChevronDown";
import { IconChevronLeft } from "./icons/IconChevronLeft";
import { IconChevronRight } from "./icons/IconChevronRight";
import { IconChevronUp } from "./icons/IconChevronUp";
import { IconCircle } from "./icons/IconCircle";
import { IconClose } from "./icons/IconClose";
import { IconCloseCircle } from "./icons/IconCloseCircle";
import { IconCloseCircleOutline } from "./icons/IconCloseCircleOutline";
import { IconCopy } from "./icons/IconCopy";
import { IconCoupon } from "./icons/IconCoupon";
import { IconCreditCard } from "./icons/IconCreditCard";
import { IconDelete } from "./icons/IconDelete";
import { IconDoneOutline } from "./icons/IconDoneOutline";
import { IconElectricCarChargingStation } from "./icons/IconElectricCarChargingStation";
import { IconEvent } from "./icons/IconEvent";
import { IconEye } from "./icons/IconEye";
import { IconEyeOff } from "./icons/IconEyeOff";
import { IconFamilyRoom } from "./icons/IconFamilyRoom";
import { IconFirstPage } from "./icons/IconFirstPage";
import { IconFitness } from "./icons/IconFitness";
import { IconFlightLand } from "./icons/IconFlightLand";
import { IconFlightSeat } from "./icons/IconFlightSeat";
import { IconFlightTakeoff } from "./icons/IconFlightTakeoff";
import { IconGift } from "./icons/IconGift";
import { IconGlobe } from "./icons/IconGlobe";
import { IconHeart } from "./icons/IconHeart";
import { IconHeartOutline } from "./icons/IconHeartOutline";
import { IconHelpCircle } from "./icons/IconHelpCircle";
import { IconHelpCircleOutline } from "./icons/IconHelpCircleOutline";
import { IconHistory } from "./icons/IconHistory";
import { IconHotel } from "./icons/IconHotel";
import { IconIndent } from "./icons/IconIndent";
import { IconInsurance } from "./icons/IconInsurance";
import { IconKitchen } from "./icons/IconKitchen";
import { IconLastPage } from "./icons/IconLastPage";
import { IconLaundry } from "./icons/IconLaundry";
import { IconLeaf } from "./icons/IconLeaf";
import { IconLightning } from "./icons/IconLightning";
import { IconLink } from "./icons/IconLink";
import { IconList } from "./icons/IconList";
import { IconLocation } from "./icons/IconLocation";
import { IconLogin } from "./icons/IconLogin";
import { IconLogout } from "./icons/IconLogout";
import { IconMagazine } from "./icons/IconMagazine";
import { IconMail } from "./icons/IconMail";
import { IconMailOutline } from "./icons/IconMailOutline";
import { IconMeals } from "./icons/IconMeals";
import { IconMenu } from "./icons/IconMenu";
import { IconMeters } from "./icons/IconMeters";
import { IconMoney } from "./icons/IconMoney";
import { IconNotificationsNone } from "./icons/IconNotificationsNone";
import { IconOption } from "./icons/IconOption";
import { IconOptions } from "./icons/IconOptions";
import { IconParking } from "./icons/IconParking";
import { IconPdf } from "./icons/IconPdf";
import { IconPdfOutline } from "./icons/IconPdfOutline";
import { IconPeople } from "./icons/IconPeople";
import { IconPerson } from "./icons/IconPerson";
import { IconPersonAdultOutline } from "./icons/IconPersonAdultOutline";
import { IconPersonChildOutline } from "./icons/IconPersonChildOutline";
import { IconPersonInfantOutline } from "./icons/IconPersonInfantOutline";
import { IconPersonOutline } from "./icons/IconPersonOutline";
import { IconPets } from "./icons/IconPets";
import { IconPoint } from "./icons/IconPoint";
import { IconPool } from "./icons/IconPool";
import { IconPrice } from "./icons/IconPrice";
import { IconPriorityHigh } from "./icons/IconPriorityHigh";
import { IconRemove } from "./icons/IconRemove";
import { IconRemoveCircle } from "./icons/IconRemoveCircle";
import { IconRemoveCircleOutline } from "./icons/IconRemoveCircleOutline";
import { IconRestaurant } from "./icons/IconRestaurant";
import { IconReview } from "./icons/IconReview";
import { IconRoomService } from "./icons/IconRoomService";
import { IconRoomSize } from "./icons/IconRoomSize";
import { IconRoomView } from "./icons/IconRoomView";
import { IconSale } from "./icons/IconSale";
import { IconSauna } from "./icons/IconSauna";
import { IconSave } from "./icons/IconSave";
import { IconSearch } from "./icons/IconSearch";
import { IconSecure } from "./icons/IconSecure";
import { IconSettings } from "./icons/IconSettings";
import { IconShare } from "./icons/IconShare";
import { IconSmartPhone } from "./icons/IconSmartPhone";
import { IconSmoke } from "./icons/IconSmoke";
import { IconSmokeOff } from "./icons/IconSmokeOff";
import { IconSort } from "./icons/IconSort";
import { IconSpa } from "./icons/IconSpa";
import { IconStar } from "./icons/IconStar";
import { IconSupportAgent } from "./icons/IconSupportAgent";
import { IconTicketFlightOutline } from "./icons/IconTicketFlightOutline";
import { IconTicketOutline } from "./icons/IconTicketOutline";
import { IconTimeOutline } from "./icons/IconTimeOutline";
import { IconTour } from "./icons/IconTour";
import { IconTourAndHotel } from "./icons/IconTourAndHotel";
import { IconTrain } from "./icons/IconTrain";
import { IconTravel } from "./icons/IconTravel";
import { IconTravelOutline } from "./icons/IconTravelOutline";
import { IconWarning } from "./icons/IconWarning";
import { IconWifi } from "./icons/IconWifi";
import { IconWifiOff } from "./icons/IconWifiOff";
import { IconBathRoom } from "./icons/IconBathRoom";
import { IconDeck } from "./icons/IconDeck";
import { IconCoffeeMaker } from "./icons/IconCoffeeMaker";
import { IconCooking } from "./icons/IconCooking";
import { IconBalcony } from "./icons/IconBalcony";
import { IconTv } from "./icons/IconTv";
import { IconKettle } from "./icons/IconKettle";
import { IconCheckOutline } from "./icons/IconCheckOutline";

export type IconType = keyof typeof icons;

interface IconProps {
  icon: IconType;
  size?: ResponsiveProp<"xs" | "sm" | "base" | "md" | "lg" | "xl" | "2xl">;
  color?:
    | "gray-80"
    | "gray-50"
    | "gray-30"
    | "green-5"
    | "green-40"
    | "yellow-50"
    | "white"
    | "black"
    | "primary"
    | "warning"
    | "positiveRed"
    | "danger";
  onClick?: MouseEventHandler<HTMLElement>;
}

export interface IconTypeProps {
  color?: "gray-80" | "gray-50" | "white";
}

export const icons = {
  accountCircle: IconAccountCircle,
  add: IconAdd,
  addCircle: IconAddCircle,
  addCircleOutline: IconAddCircleOutline,
  airport: IconAirport,
  alarmOutline: IconAlarmOutline,
  alertCircle: IconAlertCircle,
  alertCircleOutline: IconAlertCircleOutline,
  arrowDropdown: IconArrowDropDown,
  arrowDropLeft: IconArrowDropLeft,
  arrowDropRight: IconArrowDropRight,
  arrowDropUp: IconArrowDropUp,
  arrowDown: IconArrowDown,
  arrowLeft: IconArrowLeft,
  arrowRight: IconArrowRight,
  arrowUp: IconArrowUp,
  bedOutline: IconBedOutline,
  bedSingle: IconBedSingle,
  busStation: IconBusStation,
  call: IconCall,
  camera: IconCamera,
  check: IconCheck,
  checkCircle: IconCheckCircle,
  checkOutline: IconCheckOutline,
  chevronDown: IconChevronDown,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  chevronUp: IconChevronUp,
  close: IconClose,
  closeCircle: IconCloseCircle,
  closeCircleOutline: IconCloseCircleOutline,
  creditCard: IconCreditCard,
  copy: IconCopy,
  delete: IconDelete,
  doneOutline: IconDoneOutline,
  event: IconEvent,
  eye: IconEye,
  eyeOff: IconEyeOff,
  firstPage: IconFirstPage,
  flightLand: IconFlightLand,
  flightTakeoff: IconFlightTakeoff,
  heart: IconHeart,
  heartOutline: IconHeartOutline,
  helpCircle: IconHelpCircle,
  helpCircleOutline: IconHelpCircleOutline,
  history: IconHistory,
  indent: IconIndent,
  insurance: IconInsurance,
  lastPage: IconLastPage,
  link: IconLink,
  location: IconLocation,
  login: IconLogin,
  logout: IconLogout,
  mail: IconMail,
  mailOutline: IconMailOutline,
  meals: IconMeals,
  money: IconMoney,
  notificationsNone: IconNotificationsNone,
  options: IconOptions,
  pdf: IconPdf,
  pdfOutline: IconPdfOutline,
  people: IconPeople,
  person: IconPerson,
  personAdultOutline: IconPersonAdultOutline,
  personChildOutline: IconPersonChildOutline,
  personInfantOutline: IconPersonInfantOutline,
  personOutline: IconPersonOutline,
  remove: IconRemove,
  removeCircle: IconRemoveCircle,
  removeCircleOutline: IconRemoveCircleOutline,
  review: IconReview,
  roomSize: IconRoomSize,
  roomView: IconRoomView,
  meters: IconMeters,
  sale: IconSale,
  search: IconSearch,
  settings: IconSettings,
  share: IconShare,
  smoke: IconSmoke,
  smokeOff: IconSmokeOff,
  sort: IconSort,
  star: IconStar,
  supportAgent: IconSupportAgent,
  ticketFlightOutline: IconTicketFlightOutline,
  ticketOutline: IconTicketOutline,
  timeOutline: IconTimeOutline,
  train: IconTrain,
  travel: IconTravel,
  travelOutline: IconTravelOutline,
  warning: IconWarning,
  circle: IconCircle,
  globe: IconGlobe,
  gift: IconGift,
  flightSeat: IconFlightSeat,
  price: IconPrice,
  secure: IconSecure,
  list: IconList,
  car: IconCar,
  leaf: IconLeaf,
  point: IconPoint,
  coupon: IconCoupon,
  tour: IconTour,
  hotel: IconHotel,
  magazine: IconMagazine,
  tourAndHotel: IconTourAndHotel,
  option: IconOption,
  lightning: IconLightning,
  menu: IconMenu,

  // NEWT-HOTEL
  wifi: IconWifi,
  wifiOff: IconWifiOff,
  accessibility: IconAccessibility,
  airConditioning: IconAirConditioning,
  bathtub: IconBathtub,
  breakfast: IconBreakfast,
  familyRoom: IconFamilyRoom,
  fitness: IconFitness,
  laundry: IconLaundry,
  parking: IconParking,
  pool: IconPool,
  restaurant: IconRestaurant,
  roomService: IconRoomService,
  sauna: IconSauna,
  spa: IconSpa,
  "24Hours": Icon24Hours,
  kitchen: IconKitchen,
  priorityHigh: IconPriorityHigh,
  block: IconBlock,
  airportTransportation: IconAirportTransportation,
  casino: IconCasino,
  electricCarChargingStation: IconElectricCarChargingStation,
  pets: IconPets,
  smartPhone: IconSmartPhone,
  save: IconSave,
  kettle: IconKettle,
  bathRoom: IconBathRoom,
  deck: IconDeck,
  coffeeMaker: IconCoffeeMaker,
  cooking: IconCooking,
  balcony: IconBalcony,
  tv: IconTv,
};

export const Icon: FC<IconProps> = ({
  icon,
  size = "md",
  color = "gray-80",
  onClick,
}) => {
  const IconComponent = icons[icon];
  const responsiveSize = useResponsiveProp(size, "md");

  const hasOnClick = onClick !== undefined;
  const rootClasses = [
    styles.root,
    styles[`color--${color}`],
    styles[`size--${responsiveSize}`],
    hasOnClick ? styles.clickable : "",
  ].join(" ");

  return (
    <i className={rootClasses} onClick={onClick ? onClick : undefined}>
      <IconComponent />
    </i>
  );
};
