import * as yup from "yup";
import { CreditCardFormValues } from "./CreditCardForm";

export const holderNameRule = yup
  .string()
  .matches(
    /^[0-9a-zA-Z ,./-]*$/,
    "半角ローマ字でカードに記載のとおり入力してください"
  )
  .max(50, "半角ローマ字でカードに記載のとおり入力してください")
  .uppercase()
  .required("半角ローマ字でカードに記載のとおり入力してください")
  .label("カード名義人");

export const rulesForCreditCard: Record<
  keyof CreditCardFormValues,
  yup.AnySchema
> = {
  holderName: holderNameRule,
  cardNumber: yup
    .string()
    .matches(/^\d{8,19}$/, "カード番号の桁数が不正です")
    .required("カード番号の桁数が不正です")
    .label("カード番号"), // https://en.wikipedia.org/wiki/Payment_card_number
  cardCvc: yup
    .string()
    .min(3, "セキュリティコードの桁数が不正です")
    .max(4)
    .required("セキュリティコードが不正です")
    .label("セキュリティコード"),
  cardExpiry: yup
    .string()
    .test({
      test: (value) => {
        if (!value) return false;
        const [month, year] = value.toString().split(" / ");

        if (!month || !year) return false;

        // past year
        if (Number(`20${year}`) < new Date().getFullYear()) {
          return false;
        }

        // current year, but past month
        if (
          Number(year) == new Date().getFullYear() &&
          Number(month) < new Date().getMonth()
        ) {
          return false;
        }

        // invalidate month
        if (Number(month) < 1 || Number(month) > 12) {
          return false;
        }

        return true;
      },
      message: "有効期限が不正です",
    })
    .required()
    .label("有効期限"),
};
