import { ANONYMOUS_USER_TOKEN, API_HOST } from "@constants";
import { SdkFunctionWrapper, getSdk } from "@graphql/types";
import { LocalStorageService } from "@utils/webStorage";
import {
  GraphQLClient,
  RequestMiddleware,
  ResponseMiddleware,
} from "graphql-request";
import {
  sentryRequestMiddleware,
  sentryResponseMiddleware,
} from "./graphqlMiddleware";

import { getAuthorizationToken } from "./auth";
import { firebase } from "./firebase";

const getAppPath = () => {
  if (typeof window === "undefined") {
    return "unknown";
  }

  return window.location.pathname;
};

const requestMiddleware: RequestMiddleware = (request) => {
  sentryRequestMiddleware(request);
  return request;
};

const responseMiddleware: ResponseMiddleware = (response) =>
  sentryResponseMiddleware(response);

const client = new GraphQLClient(API_HOST, {
  requestMiddleware,
  responseMiddleware,
});

const setHeaders: SdkFunctionWrapper = async <T>(action: () => Promise<T>) => {
  const token =
    getAuthorizationToken() || (await firebase.auth.currentUser?.getIdToken());
  const anonymousUserToken = token
    ? undefined
    : await LocalStorageService.getItem<string>(ANONYMOUS_USER_TOKEN);

  client.setHeaders({
    authorization: token || "",
    app_id: "newt-web",
    app_path: getAppPath(),
  });

  if (anonymousUserToken) {
    client.setHeader("newt-anonymous-token", anonymousUserToken);
  }

  return action();
};

export const graphql = getSdk(client, setHeaders);
