import { Container } from "../../Container";
import { Logo } from "../../Logo";
import styles from "./BasicHeader.module.css";

export const BasicHeader = () => {
  return (
    <header>
      <div className={styles.root}>
        <Container>
          <Logo variant="horizontal" />
        </Container>
      </div>
    </header>
  );
};
