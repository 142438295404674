import {
  LOCAL_STORAGE_SECRET,
  SESSION_STORAGE_SECRET,
  IS_NOT_PRODUCTION_ENV,
} from "@constants";
import { aesDecrypt, aesEncrypt } from "../encryption/aes";

const PREFIX = "newt.";
const WARN_TEXT = {
  CLIENT: `window is not defined, web storage will not work. key:`,
  WEB_STORAGE: `web storage error. key:`,
};

export const LocalStorageService = {
  getItem: async <T extends unknown>(
    key: string
  ): Promise<T | undefined | null> => {
    if (typeof window === "undefined") return;

    try {
      const item = window.localStorage.getItem(`${PREFIX}${key}`);
      if (!item) return null;

      if (IS_NOT_PRODUCTION_ENV) {
        return JSON.parse(item);
      }

      const decrypted = await aesDecrypt(item, LOCAL_STORAGE_SECRET);
      return decrypted;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(WARN_TEXT.WEB_STORAGE, key, error);
      return null;
    }
  },

  setItem: async (key: string, value: any) => {
    if (typeof window == "undefined") {
      // eslint-disable-next-line no-console
      console.warn(WARN_TEXT.CLIENT, key);
      return;
    }

    if (IS_NOT_PRODUCTION_ENV) {
      window.localStorage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
      return;
    }

    try {
      const item = await aesEncrypt(value, LOCAL_STORAGE_SECRET);
      window.localStorage.setItem(`${PREFIX}${key}`, item);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(WARN_TEXT.WEB_STORAGE, key, error);
    }
  },

  removeItem: (key: string) => {
    if (typeof window === "undefined") return;

    try {
      window.localStorage.removeItem(`${PREFIX}${key}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(WARN_TEXT.WEB_STORAGE, key, error);
    }
  },
};

export const SessionStorageService = {
  getItem: async <T extends unknown>(
    key: string
  ): Promise<T | undefined | null> => {
    if (typeof window === "undefined") return;

    try {
      const item = window.sessionStorage.getItem(`${PREFIX}${key}`);
      if (!item) return null;

      if (IS_NOT_PRODUCTION_ENV) {
        return JSON.parse(item);
      }

      const decrypted = await aesDecrypt(item, SESSION_STORAGE_SECRET);
      return decrypted;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(WARN_TEXT.WEB_STORAGE, key, error);
      return null;
    }
  },

  setItem: async (key: string, value: any) => {
    if (typeof window === "undefined") return;

    if (IS_NOT_PRODUCTION_ENV) {
      window.sessionStorage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
      return;
    }

    try {
      const encrypted = await aesEncrypt(value, SESSION_STORAGE_SECRET);
      window.sessionStorage.setItem(`${PREFIX}${key}`, encrypted);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(WARN_TEXT.WEB_STORAGE, key, error);
    }
  },

  removeItem: (key: string) => {
    if (typeof window === "undefined") return;

    try {
      window.sessionStorage.removeItem(`${PREFIX}${key}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(WARN_TEXT.WEB_STORAGE, key, error);
    }
  },
};
