import { ChangeEventHandler, FC } from "react";
import { Icon, IconType } from "../Icon";
import styles from "./ChipBasic.module.css";

interface ChipBasicProps {
  label: string;
  value?: number | string;
  isChecked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  iconLeft?: IconType;
  iconRight?: IconType;
  size?: "sm" | "base" | "md";
}

export const ChipBasic: FC<ChipBasicProps> = (props) => {
  const {
    label,
    value,
    isChecked,
    iconLeft,
    iconRight,
    size = "md",
    onChange,
  } = props;

  const classes = [styles.root, isChecked ? styles.checked : ""].join(" ");
  const bodyClasses = [styles.body, styles[`size--${size}`]].join(" ");

  return (
    <label className={classes}>
      <input
        type="checkbox"
        className={styles.input}
        value={value}
        checked={isChecked}
        onChange={onChange}
      />
      <div className={bodyClasses}>
        {iconLeft && (
          <span className={styles.icon}>
            <Icon icon={iconLeft} size="sm" color="gray-80" />
          </span>
        )}
        <span className={styles.label}>{label}</span>
        {iconRight && (
          <span className={styles.icon}>
            <Icon icon={iconRight} size="sm" color="gray-80" />
          </span>
        )}
      </div>
    </label>
  );
};
